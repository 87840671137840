import { IPageSectionForm } from 'interfaces/page-section-form'
import { FormSection } from './form-section'
import SearchFormSection from '../rx-search-form-section'
import { IUiResources } from 'interfaces/ui-resource'
import RxTokenFormSection from '../rx-token-form-section'
import { ICommonCopyDict } from 'interfaces/common-copy'

interface IProps {
    section: IPageSectionForm
    uiResources?: IUiResources
    commonCopy?: ICommonCopyDict
    className?: string
    [key: string]: unknown
}

export const PageSectionForm = ({ section, className, uiResources, commonCopy }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    switch (section.renderType) {
        case 'Rx Search':
            return (
                <SearchFormSection
                    section={section}
                    uiResources={uiResources}
                    className={className}
                    commonCopy={commonCopy}
                />
            )
        case 'Rx Token':
            return <RxTokenFormSection section={section} className={className} />
        case 'Fixed inputs':
        case 'Full width inputs':
        case null: // old entries could have render type not set
            return <FormSection section={section} className={className} />
        default:
            return null
    }
}
