import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IconButton } from 'components/buttons/button'
import ContentfulRichText from 'components/rich-text'
import ArrowRightIcon from 'icons/arrow-right'
import { screens } from 'tailwindcss/defaultTheme'
import { BLOCKS } from '@contentful/rich-text-types'
import globalManifest from 'data/global-manifest.json'
import { colors } from 'theme.cjs'
import { getContentfulImageBackgroundQuery } from 'lib/util/image'
import Link from 'next/link'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { RenderNode } from '@contentful/rich-text-react-renderer'

interface IProps {
    section: IPageSectionVisualizer
}

const { resources: globalResources } = globalManifest ?? {}

const lgBreakPoint = screens.lg

const headerRenderNode: RenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-4xl lg:text-7xl text-center lg:text-left leading-120% tracking-tighter">{children}</p>
    ),
}

const intersectionObserverOptions: IntersectionObserverInit = { threshold: 0.7 }

export const VisualizerSlidesWithBackgroundColorSection = ({ section }: IProps): React.ReactElement => {
    const { richTitle, visualsCollection, ctaLink, backgroundColor, verticalSpacing } = section ?? {}
    const visuals = visualsCollection?.items || []
    const [activeVisualIndex, setActiveVisualIndex] = React.useState(0)

    const [sectionElement, setSectionElement] = React.useState<HTMLElement>()
    const [sectionHasBeenScrolledTo, setSectionHasBeenScrolledTo] = React.useState(false)

    const timerRef = React.useRef<NodeJS.Timeout | null>(null)

    const sectionRef = React.useCallback((node) => {
        if (node !== null) {
            setSectionElement(node)
        }
    }, [])

    const { entry } = useIntersectionObserver({
        element: sectionElement,
        options: intersectionObserverOptions,
    })

    const clearAutoplayTimer = React.useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current)
            timerRef.current = null
        }
    }, [])

    const goToPrevSlide = React.useCallback(() => {
        clearAutoplayTimer()
        setActiveVisualIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : visuals.length - 1))
    }, [visuals.length, clearAutoplayTimer])

    const goToNextSlide = React.useCallback(() => {
        clearAutoplayTimer()
        setActiveVisualIndex((prevIndex) => (prevIndex < visuals.length - 1 ? prevIndex + 1 : 0))
    }, [visuals.length, clearAutoplayTimer])

    React.useEffect(() => {
        if (entry?.isIntersecting && !sectionHasBeenScrolledTo) {
            setSectionHasBeenScrolledTo(true)
        }
    }, [entry?.isIntersecting, sectionHasBeenScrolledTo])

    React.useEffect(() => {
        if (!entry?.isIntersecting) {
            return
        }

        timerRef.current = setInterval(() => {
            setActiveVisualIndex((prevIndex) => (prevIndex < visuals.length - 1 ? prevIndex + 1 : 0))
        }, 3000)

        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [visuals.length, entry?.isIntersecting])

    if (!visuals.length) {
        return null
    }

    const contentfulImageBGQuery = getContentfulImageBackgroundQuery(backgroundColor)
    const spacingClassName = verticalSpacing === 'Tight (mobile)' ? 'max-lg:section--clear-margin-top' : ''

    return (
        <section ref={sectionRef} className={`sm:px-6 lg:px-15 px-0 lg:pl-15 grid ${spacingClassName}`}>
            <div className="lg:hidden col-start-1 col-end-2 row-start-1 row-end-2 px-6">
                {richTitle && (
                    <ContentfulRichText content={richTitle.json} renderNode={headerRenderNode} className="mb-2" />
                )}
            </div>

            <div className="flex flex-col justify-start xl:mt-24 items-center py-12.5 lg:py-0 lg:pr-7 col-start-1 col-end-2 row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 lg:col-start-1 z-10 lg:w-max relative">
                <div>
                    {richTitle && (
                        <ContentfulRichText
                            content={richTitle.json}
                            renderNode={headerRenderNode}
                            className="lg:mb-8 hidden lg:block"
                        />
                    )}
                    <div className="hidden lg:block max-w-sm rounded-l-2lg overflow-hidden">
                        <div className="font-bold">{visuals[activeVisualIndex].title}</div>
                        <p className="mt-1 leading-140% h-16">{visuals[activeVisualIndex].description}</p>
                        <div className="flex justify-start mt-6 w-full">
                            <IconButton
                                onClick={goToPrevSlide}
                                className="bg-transparent border border-black rounded-full h-9 w-9 flex justify-center items-center mr-4 transition-colors duration-300 hover:bg-black group"
                                label={globalResources?.['prevLabel']?.value as string}
                            >
                                <ArrowRightIcon
                                    strokeWidth={2}
                                    stroke={colors.mineshaft}
                                    className="transition-colors duration-200 group-hover:stroke-white rotate-180"
                                    isHover={true}
                                />
                            </IconButton>
                            <IconButton
                                onClick={goToNextSlide}
                                className="bg-transparent border border-black rounded-full h-9 w-9 flex justify-center items-center mr-4 transition-colors duration-200 hover:bg-black group"
                                label={globalResources?.['nextLabel']?.value as string}
                            >
                                <ArrowRightIcon
                                    strokeWidth={2}
                                    stroke={colors.mineshaft}
                                    className="transition-colors duration-300 group-hover:stroke-white"
                                    isHover={true}
                                />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid relative w-full h-full col-start-1 col-end-2 row-start-2 row-end-3 lg:row-start-1 lg:row-end-2 lg:col-start-2 lg:col-end-3">
                {visuals.map((visual, index) => {
                    const mobileImage = visual.mobileImagesCollection?.items?.[0]
                    const desktopImage = visual.desktopImagesCollection?.items?.[0]

                    if (!mobileImage && !desktopImage) {
                        return null
                    }

                    if (index !== 0 && !sectionHasBeenScrolledTo) {
                        return null
                    }

                    return (
                        <div
                            key={visual.sys?.id}
                            className={`relative col-start-1 col-end-2 row-start-1 row-end-2 first-letter transition-opacity duration-500 px-6
                                ${activeVisualIndex === index ? 'opacity-100' : 'opacity-0'}
                                rounded-l-2lg lg:overflow-hidden
                            `}
                        >
                            <picture>
                                {desktopImage && (
                                    <>
                                        <source
                                            media={`(min-width: ${lgBreakPoint})`}
                                            type="image/webp"
                                            srcSet={`${desktopImage.url}?fm=webp&w=1024 1024w, ${desktopImage.url}?fm=webp&w=1280 1280w, ${desktopImage.url}?fm=webp&w=1440 1440w, ${desktopImage.url}?fm=webp&w=1600 1600w, ${desktopImage.url}?fm=webp&w=2400 2400w, ${desktopImage.url}?fm=webp&w=3200 3200w`}
                                        />
                                        <source
                                            media={`(min-width: ${lgBreakPoint})`}
                                            type="image/jpeg"
                                            srcSet={`${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=1024 1024w, ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=1280 1280w, ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=1440 1440w, ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=1600 1600w, ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=2400 2400w, ${desktopImage.url}?fm=jpg&${contentfulImageBGQuery}&w=3200 3200w`}
                                        />
                                    </>
                                )}
                                {mobileImage && (
                                    <>
                                        <source
                                            type="image/webp"
                                            srcSet={`${mobileImage.url}?fm=webp&w=320 320w, ${mobileImage.url}?fm=webp&w=420 420w, ${mobileImage.url}?fm=webp&w=640 640w, ${mobileImage.url}?fm=webp&w=840 840w, ${mobileImage.url}?fm=webp&w=1024 1024w`}
                                        />
                                        <source
                                            type="image/jpeg"
                                            srcSet={`${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=320 320w, ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=420 420w, ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=640 640w, ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=840 840w, ${mobileImage.url}?fm=jpg&${contentfulImageBGQuery}&w=1024 1024w`}
                                        />
                                    </>
                                )}
                                <img
                                    src={`${
                                        mobileImage?.url || desktopImage?.url
                                    }?fm=jpg&w=1024&${contentfulImageBGQuery}`}
                                    alt={
                                        mobileImage?.title ||
                                        desktopImage?.title ||
                                        mobileImage?.description ||
                                        desktopImage?.description
                                    }
                                    height={mobileImage?.height || desktopImage?.height}
                                    width={mobileImage?.width || desktopImage?.width}
                                    className="h-full lg:max-h-[min(calc(var(--screenHeightDeductingHeader)-80px),600px)] object-contain"
                                    loading="lazy"
                                    style={{ backgroundColor }}
                                />
                            </picture>
                        </div>
                    )
                })}
            </div>

            {ctaLink?.href && (
                <Link
                    href={ctaLink.href}
                    className="button button-primary section--primary-cta hidden lg:inline-block justify-self-start mt-14 lg:px-12 lg:py-2.5 text-xbase"
                >
                    {ctaLink.name}
                </Link>
            )}

            <div className="lg:hidden overflow-hidden relative mt-8">
                <div className="text-center leading-140% flex flex-col !justify-start">
                    <div className="text-xl font-bold">{visuals[activeVisualIndex].title}</div>
                    <p className="text-center text-lg px-8 h-24">{visuals[activeVisualIndex].description}</p>
                </div>
                <div className="flex justify-center mt-8 space-x-2">
                    <IconButton
                        onClick={goToPrevSlide}
                        className="bg-transparent lg:hidden border border-black rounded-full h-9 w-9 flex justify-center items-center transition-colors duration-300 active:bg-black group"
                        label={globalResources?.['prevLabel']?.value as string}
                    >
                        <ArrowRightIcon
                            strokeWidth={2}
                            stroke={colors.mineshaft}
                            className="rotate-180 transition-colors duration-300 group-active:stroke-white"
                        />
                    </IconButton>
                    <IconButton
                        onClick={goToNextSlide}
                        className="bg-transparent lg:hidden border border-black rounded-full h-9 w-9 flex justify-center items-center transition-colors duration-300 active:bg-black group"
                        label={globalResources?.['nextLabel']?.value as string}
                    >
                        <ArrowRightIcon
                            strokeWidth={2}
                            stroke={colors.mineshaft}
                            className="transition-colors duration-300 group-active:stroke-white"
                        />
                    </IconButton>
                </div>
            </div>

            {ctaLink?.href && (
                <Link href={ctaLink.href} className="button button-primary block lg:hidden mt-6 mx-5 text-xbase">
                    {ctaLink.name}
                </Link>
            )}
        </section>
    )
}
