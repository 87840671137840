import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import styles from './style.module.css'
import { IPicture } from 'interfaces/picture'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg
const desktopCardWidth = 375

const Card = ({ visual }: { visual: IPicture }): React.ReactElement => {
    const { mobileImagesCollection, desktopImagesCollection, cta } = visual

    const mobileImage = mobileImagesCollection.items?.[0]
    const desktopImage = desktopImagesCollection.items?.[0]

    return (
        <Link href={cta?.href ?? ''} className="relative h-full">
            <div className="rounded-2lg overflow-hidden relative lg:h-full">
                {/* card image */}
                {(mobileImage?.url || desktopImage?.url) && (
                    <picture className="relative pt-[125%] block h-full">
                        {desktopImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                                    ${
                                                        desktopImage.url
                                                    }?fm=webp&w=${desktopCardWidth} ${desktopCardWidth}w,
                                                    ${desktopImage.url}?fm=webp&w=${desktopCardWidth * 2} ${
                                        desktopCardWidth * 2
                                    }w
                                                `}
                                    sizes={desktopCardWidth + 'px'}
                                />

                                <source
                                    type="image/jpeg"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={`
                                                    ${
                                                        desktopImage.url
                                                    }?fm=jpg&w=${desktopCardWidth} ${desktopCardWidth}w,
                                                    ${desktopImage.url}?fm=jpg&w=${desktopCardWidth * 2} ${
                                        desktopCardWidth * 2
                                    }w
                                                `}
                                    sizes={desktopCardWidth + 'px'}
                                />
                            </>
                        )}

                        {mobileImage?.url && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                                    ${mobileImage.url}?fm=webp&w=420 420w,
                                                    ${mobileImage.url}?fm=webp&w=640 640w
                                                `}
                                    sizes="80vw"
                                />

                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                                    ${mobileImage.url}?fm=jpg&w=420 420w,
                                                    ${mobileImage.url}?fm=jpg&w=640 640w
                                                `}
                                    // todo sizes
                                    sizes="80vw"
                                />
                            </>
                        )}

                        <img
                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                            alt={
                                mobileImage?.description ||
                                desktopImage?.description ||
                                mobileImage?.title ||
                                desktopImage?.title
                            }
                            height={mobileImage?.height || desktopImage?.height}
                            width={mobileImage?.width || desktopImage?.width}
                            loading="lazy"
                            className="h-full object-cover absolute top-0 left-0"
                        />
                    </picture>
                )}

                {/* card cta */}
                <span className="button bg-white border-white absolute bottom-5 left-5 right-5">{cta.name}</span>
            </div>
        </Link>
    )
}

export const VisualizerStackingCardsSection = ({ section }: IProps): React.ReactElement => {
    const [activeVisualIndex, setActiveVisualIndex] = React.useState(0)
    const desktopCardsContainerRef = React.useRef<HTMLDivElement>()

    if (!section) {
        return null
    }

    const { backgroundColor, visualsCollection } = section

    const handleLeftButtonClick = () => {
        setActiveVisualIndex(activeVisualIndex - 1)
    }

    const handleRightButtonClick = () => {
        setActiveVisualIndex(activeVisualIndex + 1)
    }

    const totalVisuals = visualsCollection.items?.length

    return (
        <section
            className={`section--v2 ${
                backgroundColor ? 'max-lg:section--small-margin-top' : ''
            } bg-[color:var(--bgColor)] lg:bg-transparent overflow-hidden`}
            style={{ ['--bgColor' as string]: backgroundColor }}
        >
            {/* mobile layout */}
            <div className="-mx-5 sm:-mx-6 lg:mx-0 lg:hidden">
                {/* 80% */}
                <div className="flex overflow-auto scrollbar-hidden snap-x scroll-px-5 sm:scroll-px-6 pl-5 sm:pl-6">
                    {visualsCollection.items.map((visual) => {
                        const { description, title, sys } = visual

                        return (
                            <div key={sys.id} className="w-4/5 snap-center pr-5 last:sm:pr-6 flex-shrink-0">
                                <Card visual={visual} />
                                <figure className="mt-9">
                                    <blockquote
                                        className={`text-lg font-medium tracking-tight leading-120% blockquote ${styles['description']}`}
                                    >
                                        {description}
                                    </blockquote>
                                    <figcaption className="mt-12">
                                        <cite className="text-xsm font-bold not-italic tracking-wider">{title}</cite>
                                    </figcaption>
                                </figure>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* desktop layout */}
            <div
                className="hidden lg:grid grid-flow-col auto-cols-fr rounded-2lg py-12.5 min-h-[450px] relative"
                style={{ backgroundColor }}
            >
                {/* cards */}
                <div className="relative">
                    <div className="flex flex-row-reverse absolute right-24 h-full" ref={desktopCardsContainerRef}>
                        {visualsCollection.items.map((visual, index) => {
                            return (
                                <div
                                    key={visual.sys.id}
                                    className={`relative ml-5 last:ml-0 flex-shrink-0 h-full translate-x-[var(--translateX)] transition-transform ${
                                        index - activeVisualIndex <= 0 && index !== 0 ? 'shadow-md' : ''
                                    }`}
                                    style={{
                                        ['--translateX' as string]:
                                            Math.min(index * desktopCardWidth, activeVisualIndex * desktopCardWidth) +
                                            'px',
                                        width: desktopCardWidth + 'px',
                                        zIndex: index,
                                    }}
                                >
                                    <Card visual={visual} />
                                </div>
                            )
                        })}
                    </div>
                </div>

                {/* descriptions */}
                <div className="grid">
                    {visualsCollection.items.map((visual, index) => {
                        const { sys, description, title } = visual

                        const isActive = index === activeVisualIndex

                        return (
                            <figure
                                key={sys.id}
                                className={`p-5 pr-20 pb-24 flex flex-col justify-center row-start-1 row-end-2 col-start-1 col-end-2 transition-opacity ${
                                    isActive ? '' : 'opacity-0'
                                }`}
                            >
                                <figcaption>
                                    <cite className="font-medium not-italic">{title}</cite>
                                </figcaption>
                                <blockquote className="blockquote text-3.25xl font-medium leading-120% tracking-tight mt-7">
                                    {description}
                                </blockquote>
                            </figure>
                        )
                    })}
                </div>

                {/* arrow buttons */}
                <div className="absolute left-1/2 -translate-x-1/2 bottom-8 flex">
                    <button
                        onClick={handleLeftButtonClick}
                        disabled={activeVisualIndex <= 0}
                        className="rounded-full border border-black h-9 w-9 flex justify-center items-center disabled:opacity-30"
                    >
                        <ArrowRightIcon className="rotate-180" stroke={colors.mineshaft} strokeWidth={2} />
                    </button>
                    <button
                        onClick={handleRightButtonClick}
                        disabled={activeVisualIndex >= totalVisuals - 1}
                        className="rounded-full border border-black ml-5 h-9 w-9 flex justify-center items-center disabled:opacity-30"
                    >
                        <ArrowRightIcon stroke={colors.mineshaft} strokeWidth={2} />
                    </button>
                </div>
            </div>
        </section>
    )
}
