import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import ArrowRightIcon from 'icons/arrow-right'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'

const { screens } = twConfig
import { colors } from 'theme.cjs'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { easeInOutQuad } from 'lib/util/misc'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg
const mobileCardWidth = 290
const desktopCardWidth = 375

const titleRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="text-1xl lg:text-3.25xl font-medium tracking-tight">{children}</h2>
    ),
}

export const VisualizerCarouselSection = ({ section }: IProps): React.ReactElement => {
    const scrollableContainerRef = React.useRef<HTMLDivElement>()

    const [isSmoothScrollingSupported, setIsSmoothScrollingSupported] = React.useState(true)
    const [doesSectionHaveScrollbar, setDoesSectionHaveScrollbar] = React.useState(false)
    const [isLeftArrowDisabled, setIsLeftArrowDisabled] = React.useState(true)
    const [isRightArrowDisabled, setIsRightArrowDisabled] = React.useState(false)

    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        setIsSmoothScrollingSupported(window.CSS.supports('scroll-behavior', 'smooth'))

        setDoesSectionHaveScrollbar(
            scrollableContainerRef.current?.scrollWidth > scrollableContainerRef.current?.clientWidth,
        )

        let timer: NodeJS.Timeout

        const windowResizeHandler = () => {
            clearTimeout(timer)

            timer = setTimeout(() => {
                setDoesSectionHaveScrollbar(
                    scrollableContainerRef.current?.scrollWidth > scrollableContainerRef.current?.clientWidth,
                )
            }, 1000)
        }

        window.addEventListener('resize', windowResizeHandler)

        return () => {
            window.removeEventListener('resize', windowResizeHandler)
        }
    }, [])

    if (!section) {
        return null
    }

    const { richTitle, visualsCollection } = section

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const scrollLeft = e.currentTarget.scrollLeft

        if (scrollLeft === 0) {
            setIsLeftArrowDisabled(true)
        } else {
            setIsLeftArrowDisabled(false)
        }

        if (e.currentTarget.clientWidth + scrollLeft >= e.currentTarget.scrollWidth) {
            setIsRightArrowDisabled(true)
        } else {
            setIsRightArrowDisabled(false)
        }
    }

    const handleLeftButtonClick = () => {
        let cardWidth = desktopCardWidth
        if (isMobileScreen) {
            cardWidth = mobileCardWidth
        }

        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContainerRef.current.scrollBy({ left: cardWidth * -1, behavior: 'smooth' })
            return
        }

        const startTime = performance.now()
        const duration = 0.5
        const startPosition = scrollableContainerRef.current.scrollLeft
        const endPosition = startPosition - cardWidth < 0 ? 0 : startPosition - cardWidth
        const scrollBy = endPosition - startPosition

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.floor(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))
            scrollableContainerRef.current.scrollLeft = newPosition

            if (scrollableContainerRef.current.scrollLeft > endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }

    const handleRightButtonClick = () => {
        let cardWidth = desktopCardWidth
        if (isMobileScreen) {
            cardWidth = mobileCardWidth
        }

        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContainerRef.current.scrollBy({ left: cardWidth, behavior: 'smooth' })
            return
        }

        const startTime = performance.now()
        const duration = 0.5
        const scrollWidth = scrollableContainerRef.current.scrollWidth
        const clientWidth = scrollableContainerRef.current.clientWidth

        const startPosition = scrollableContainerRef.current.scrollLeft
        const endPosition =
            startPosition + cardWidth > scrollWidth - clientWidth
                ? scrollWidth - clientWidth
                : startPosition + cardWidth

        const scrollBy = endPosition - startPosition

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.ceil(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))

            scrollableContainerRef.current.scrollLeft = newPosition

            if (scrollableContainerRef.current.scrollLeft < endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }

    return (
        <section className="section--v2">
            {/* title + arrow buttons */}
            <div className="flex justify-between items-center">
                <ContentfulRichText content={richTitle.json} renderNode={titleRenderNode} ignoreMarkdownStyles />

                {doesSectionHaveScrollbar && (
                    // arrow buttons
                    <div className="flex ml-5">
                        {/* left */}
                        <button
                            className="h-9 w-9 rounded-full border flex justify-center items-center disabled:opacity-30"
                            disabled={isLeftArrowDisabled}
                            onClick={handleLeftButtonClick}
                        >
                            <ArrowRightIcon className="rotate-180" strokeWidth={2} stroke={colors.mineshaft} />
                        </button>

                        {/* right */}
                        <button
                            className="h-9 w-9 rounded-full border flex justify-center items-center ml-2 disabled:opacity-30"
                            disabled={isRightArrowDisabled}
                            onClick={handleRightButtonClick}
                        >
                            <ArrowRightIcon strokeWidth={2} stroke={colors.mineshaft} />
                        </button>
                    </div>
                )}
            </div>

            {/* cards */}
            {visualsCollection.items.length > 0 && (
                <div
                    className={`mt-8 lg:mt-9 overflow-auto -mx-5 sm:-mx-6 lg:-mx-15 scrollbar-hidden ${
                        isSmoothScrollingSupported ? 'snap-x' : ''
                    }`}
                    ref={scrollableContainerRef}
                    onScroll={handleScroll}
                >
                    {/* padding right isn't applied, not sure why
                    - using pseudo element with border for space */}
                    <div className="px-5 sm:px-6 lg:px-15 flex after:border-[10px] after:sm:border-[12px] after:lg:border-[30px] after:border-transparent">
                        {visualsCollection.items.map((visual) => {
                            const mobileImage = visual.mobileImagesCollection.items[0]
                            const desktopImage = visual.desktopImagesCollection.items[0]

                            const title = visual.title?.replace('{min_quan}', '').replace('{discount}', '')
                            const cta = visual.cta

                            return (
                                <div
                                    key={visual.sys?.id}
                                    // only snap if smooth scrolling is supported by browser, for
                                    // those that don't we're programmatically scrolling which
                                    // conflicts with snap behaviour
                                    className={`w-[var(--mobileWidth)] lg:w-[var(--desktopWidth)] first:ml-0 ml-5 flex-shrink-0 flex flex-col ${
                                        isSmoothScrollingSupported ? 'snap-center' : ''
                                    }`}
                                    style={{
                                        ['--mobileWidth' as string]: mobileCardWidth + 'px',
                                        ['--desktopWidth' as string]: desktopCardWidth + 'px',
                                    }}
                                >
                                    <div className="rounded-2lg overflow-hidden">
                                        {(mobileImage?.url || desktopImage?.url) && (
                                            <Link href={cta?.href ?? ''}>
                                                <picture>
                                                    {desktopImage?.url && (
                                                        <>
                                                            <source
                                                                type="image/webp"
                                                                media={`(min-width: ${lgBreakPoint})`}
                                                                srcSet={`
                                                            ${desktopImage.url}?fm=webp&w=420 420w,
                                                            ${desktopImage.url}?fm=webp&w=640 640w
                                                        `}
                                                                sizes="30vw"
                                                            />
                                                            <source
                                                                type="image/jpeg"
                                                                media={`(min-width: ${lgBreakPoint})`}
                                                                srcSet={`
                                                            ${desktopImage.url}?fm=jpg&w=420 420w,
                                                            ${desktopImage.url}?fm=jpg&w=640 640w
                                                        `}
                                                                sizes="30vw"
                                                            />
                                                        </>
                                                    )}

                                                    {mobileImage?.url && (
                                                        <>
                                                            <source
                                                                type="image/webp"
                                                                srcSet={`
                                                            ${mobileImage.url}?fm=webp&w=375 375w,
                                                            ${mobileImage.url}?fm=webp&w=420 420w,
                                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                                        `}
                                                                sizes="80vw"
                                                            />
                                                            <source
                                                                type="image/jpeg"
                                                                srcSet={`
                                                            ${mobileImage.url}?fm=jpg&w=375 375w,
                                                            ${mobileImage.url}?fm=jpg&w=420 420w,
                                                            ${mobileImage.url}?fm=jpg&w=640 640w,
                                                        `}
                                                                sizes="80vw"
                                                            />
                                                        </>
                                                    )}

                                                    <img
                                                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                                                        alt={
                                                            mobileImage?.description ||
                                                            desktopImage?.description ||
                                                            mobileImage?.title ||
                                                            desktopImage?.title
                                                        }
                                                        height={mobileImage?.height || desktopImage?.height}
                                                        width={mobileImage?.width || desktopImage?.width}
                                                        className="h-[400px] lg:h-[450px] w-full object-cover"
                                                        loading="lazy"
                                                    />
                                                </picture>
                                            </Link>
                                        )}
                                    </div>

                                    <div className="mt-7.5 lg:mt-5 flex-grow flex flex-col justify-between">
                                        <div className="text-xbase lg:text-base leading-140% font-medium">{title}</div>

                                        {cta?.href && (
                                            <Link
                                                href={cta.href}
                                                className="mt-7 button button--secondary inline-block self-start"
                                            >
                                                {cta.name}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </section>
    )
}
