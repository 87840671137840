import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import Link from 'next/link'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg

const titleRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="text-1xl lg:text-3.25xl font-medium tracking-tight">{children}</h2>
    ),
}

export const VisualizerGridCardsSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { richTitle, visualsCollection, ctaLink, backgroundColor } = section

    return (
        <section className="section--v2" style={{ backgroundColor }}>
            {/* title */}
            <ContentfulRichText content={richTitle.json} renderNode={titleRenderNode} ignoreMarkdownStyles />

            {/* cards */}
            {visualsCollection.items.length > 0 && (
                <div className="mt-8 lg:mt-10 grid lg:grid-cols-4 gap-5">
                    {visualsCollection.items.map((visual) => {
                        const mobileImage = visual.mobileImagesCollection.items[0]
                        const desktopImage = visual.desktopImagesCollection.items[0]

                        const title = visual.title

                        return (
                            <div key={visual.sys?.id} className="flex flex-col">
                                <div className="rounded-2lg overflow-hidden">
                                    {(mobileImage?.url || desktopImage?.url) && (
                                        <picture>
                                            {desktopImage?.url && (
                                                <>
                                                    <source
                                                        type="image/webp"
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        srcSet={`
                                                            ${desktopImage.url}?fm=webp&w=420 420w,
                                                            ${desktopImage.url}?fm=webp&w=640 640w
                                                        `}
                                                        sizes="30vw"
                                                    />
                                                    <source
                                                        type="image/jpeg"
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        srcSet={`
                                                            ${desktopImage.url}?fm=jpg&w=420 420w,
                                                            ${desktopImage.url}?fm=jpg&w=640 640w
                                                        `}
                                                        sizes="30vw"
                                                    />
                                                </>
                                            )}

                                            {mobileImage?.url && (
                                                <>
                                                    <source
                                                        type="image/webp"
                                                        srcSet={`
                                                            ${mobileImage.url}?fm=webp&w=375 375w,
                                                            ${mobileImage.url}?fm=webp&w=420 420w,
                                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                                        `}
                                                        sizes="80vw"
                                                    />
                                                    <source
                                                        type="image/jpeg"
                                                        srcSet={`
                                                            ${mobileImage.url}?fm=jpg&w=375 375w,
                                                            ${mobileImage.url}?fm=jpg&w=420 420w,
                                                            ${mobileImage.url}?fm=jpg&w=640 640w,
                                                        `}
                                                        sizes="80vw"
                                                    />
                                                </>
                                            )}

                                            <img
                                                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=420'}
                                                alt={
                                                    mobileImage?.description ||
                                                    desktopImage?.description ||
                                                    mobileImage?.title ||
                                                    desktopImage?.title
                                                }
                                                height={mobileImage?.height || desktopImage?.height}
                                                width={mobileImage?.width || desktopImage?.width}
                                                className="h-[375px] w-full object-cover"
                                                loading="lazy"
                                            />
                                        </picture>
                                    )}
                                </div>

                                <div className="mt-6 lg:mt-5 text-xbase lg:text-base leading-140% font-medium">
                                    {title}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}

            {/* cta */}
            {ctaLink?.href && (
                <div className="flex justify-center mt-9">
                    <Link
                        href={ctaLink.href}
                        className="button button-secondary no-hover section--primary-cta block lg:inline"
                    >
                        {ctaLink.name}
                    </Link>
                </div>
            )}
        </section>
    )
}
