import * as React from 'react'
import { IPageSectionHTML } from 'interfaces/page-section-html'
import { getItemFromSS } from 'lib/util/storage'

interface IProps {
    section: IPageSectionHTML
}

const PageSectionHTML = ({ section }: IProps): React.ReactElement => {
    // fetch utm query parameters from session storage
    // and update the url of the current page
    React.useEffect(() => {
        try {
            const sessionStorageKeys = Object.keys(sessionStorage)
            let utmParameters = '?'

            for (const key of sessionStorageKeys) {
                if (key.startsWith('utm_')) {
                    utmParameters += `${key}=${getItemFromSS(key)}&`
                }
            }

            // utmParameters starts with len = 1
            if (utmParameters.length > 1) {
                history.replaceState({}, '', utmParameters.slice(0, -1))
            }
        } catch {}
    }, [])

    return (
        <>
            <section dangerouslySetInnerHTML={{ __html: section.html }} />
        </>
    )
}

export default PageSectionHTML
