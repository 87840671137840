import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IPicture } from 'interfaces/picture'
import twConfig from 'tailwind-light-config'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import Switch from 'components/switch'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import Link from 'next/link'
import CabinetSquareLogo from 'icons/cabinet-square-logo'
import { IUiResources } from 'interfaces/ui-resource'
import { blogVisualizerTextBubblesEntryInternalName } from 'misc-variables'

const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
    className?: string
    uiResources?: IUiResources
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const titleRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-3.25xl font-medium leading-120% tracking-tight">{children}</h2>
    ),
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xxs sm:text-sm text-black font-medium hover:font-bold leading-2 py-2 sm:py-2 px-4 sm:px-5 bg-white rounded-full border border-black/50 shadow-sm hover:shadow-xl cursor-pointer my-4 text-right w-max ml-auto transition-shadow duration-300">
            {children}
        </p>
    ),
}

const Picture = ({ picture, isMobileScreen }: { picture: IPicture; isMobileScreen: boolean }): React.ReactElement => {
    if (!picture) {
        return null
    }

    const { mobileImagesCollection, desktopImagesCollection, richDescription, cta } = picture
    const mobileImage = mobileImagesCollection?.items[0]
    const desktopImage = desktopImagesCollection?.items[0]

    if (!mobileImage?.url && !desktopImage?.url) {
        return null
    }

    const textBubbles = () => {
        const content = <ContentfulRichText content={richDescription.json} renderNode={paragraphRenderNode} />
        if (cta?.href) {
            return (
                <Link href={cta.href}>
                    <ContentfulRichText content={richDescription.json} renderNode={paragraphRenderNode} />
                </Link>
            )
        }
        return content
    }

    return (
        <>
            <picture className="relative">
                {desktopImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            media={`(min-width: ${lgBreakPoint})`}
                            srcSet={`
                                ${desktopImage.url}?fm=webp&w=1280 1280w,
                                ${desktopImage.url}?fm=webp&w=1600 1600w
                            `}
                            sizes={`(min-width: ${_2xlBreakPoint}) 1280px, 70vw`}
                        />

                        <source
                            type="image/jpeg"
                            media={`(min-width: ${lgBreakPoint})`}
                            srcSet={`
                                ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                ${desktopImage.url}?fm=jpg&w=1600 1600w
                            `}
                            sizes={`(min-width: ${_2xlBreakPoint}) 1280px, 70vw`}
                        />
                    </>
                )}

                {mobileImage?.url && (
                    <>
                        <source
                            type="image/webp"
                            srcSet={`
                                ${mobileImage.url}?fm=webp&w=420 420w,
                                ${mobileImage.url}?fm=webp&w=640 640w,
                                ${mobileImage.url}?fm=webp&w=800 800w,
                                ${mobileImage.url}?fm=webp&w=1024 1024w
                            `}
                        />

                        <source
                            type="image/jpeg"
                            media={`(min-width: ${lgBreakPoint})`}
                            srcSet={`
                                ${mobileImage.url}?fm=jpg&w=420 420w,
                                ${mobileImage.url}?fm=jpg&w=640 640w,
                                ${mobileImage.url}?fm=jpg&w=800 800w,
                                ${mobileImage.url}?fm=jpg&w=1024 1024w
                            `}
                        />
                    </>
                )}

                <img
                    src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                    alt={
                        mobileImage?.description ||
                        desktopImage?.description ||
                        mobileImage?.title ||
                        desktopImage?.title
                    }
                    height={mobileImage?.height || desktopImage?.height}
                    width={mobileImage?.width || desktopImage?.width}
                    loading="lazy"
                    className="h-full w-full object-cover"
                />
            </picture>
            <div
                className={`!absolute sm:right-10 sm:top-10 top-5 right-5 opacity-100 ${
                    isMobileScreen ? 'sm:opacity-0 sm:group-hover:opacity-100 transition-opacity duration-300' : ''
                } `}
            >
                <div className="flex flex-col items-end justify-between sm:space-y-8 space-y-4">{textBubbles()}</div>
            </div>
        </>
    )
}

export const VisualizerTextBubbleSection = ({ section, className = '', uiResources }: IProps): React.ReactElement => {
    const [activeState, setActiveState] = React.useState<'before' | 'after'>('before')
    const [hasUserInteracted, setHasUserInteracted] = React.useState(false) // has user toggled the before/after radio
    const [imageContainerEl, setImageContainerEl] = React.useState<HTMLDivElement>()

    const isMobileScreen = useMobileScreenDetection()

    const imageContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setImageContainerEl(node)
        }
    }, [])

    const { entry, observer } = useIntersectionObserver({ element: imageContainerEl })

    React.useEffect(() => {
        // autotoggle is stopped after user interaction
        // disconnect observer
        if (hasUserInteracted) {
            observer?.disconnect()
        }
    }, [hasUserInteracted, observer])

    const onFirstOptionSelected = React.useCallback(() => {
        setActiveState('before')
    }, [])

    const onSecondOptionSelected = React.useCallback(() => {
        setActiveState('after')
    }, [])

    const onUserInteracted = React.useCallback(() => {
        setHasUserInteracted(true)
    }, [])

    if (!section) {
        return null
    }

    const { visualsCollection, richTitle, internalName } = section

    const beforePicture = visualsCollection.items[0]
    const afterPicture = visualsCollection.items[1]

    const afterPictureWithLogo = (
        <div className="flex items-center gap-x-1">
            <CabinetSquareLogo height="24" width="24" className="rounded-lg" />
            {uiResources?.['CabinetHealthCompanyName']?.value}
        </div>
    )

    return (
        <section className={`section--v2 ${className}`}>
            {/* section title */}
            {richTitle?.json && <ContentfulRichText renderNode={titleRenderNode} content={richTitle.json} />}
            <div className="grid grid-cols-1 gap-7.5 lg:gap-5 mt-8">
                {/* before and after togglable section */}
                <div className="relative group" ref={imageContainerRef}>
                    <div className="grid rounded-2lg overflow-hidden h-full">
                        {/* before image */}
                        <div className={`${activeState === 'before' ? '' : 'invisible'} col-start-1 row-start-1`}>
                            <Picture picture={beforePicture} isMobileScreen={isMobileScreen} />
                        </div>
                        <div className={`${activeState === 'after' ? '' : 'invisible'} row-start-1 col-start-1`}>
                            <Picture picture={afterPicture} isMobileScreen={isMobileScreen} />
                        </div>
                    </div>

                    {/* toggle inputs */}
                    <Switch
                        firstOptionLabel={beforePicture?.title}
                        secondOptionLabel={
                            internalName === blogVisualizerTextBubblesEntryInternalName
                                ? afterPictureWithLogo
                                : afterPicture?.title
                        }
                        className="!absolute left-5 bottom-5 sm:bottom-10"
                        onFirstOptionSelected={onFirstOptionSelected}
                        onSecondOptionSelected={onSecondOptionSelected}
                        onUserInteracted={onUserInteracted}
                        autoSwitch={entry?.isIntersecting && !hasUserInteracted}
                    />
                </div>
            </div>
        </section>
    )
}
