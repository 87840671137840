import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import VisualizerCollectionSection from './visualizer-collection-section'
import VisualizerGridSection from './visualizer-grid-section'
import { IUiResources } from 'interfaces/ui-resource'
import VisualizerSlidesSection from './visualizer-slides-section'
import VisualizerTimelineSection from './visualizer-timeline-section'
import VisualizerCarouselSection from './visualizer-carousel-section'
import VisualizerSlidesWithBackgroundColorSection from './visualizer-slides-with-background-color-section'
import VisualizerBeforeAndAfterSection from './visualizer-before-and-after-section'
import VisualizerStackingCardsSection from './visualizer-stacking-cards-section'
import VisualizerCardsSection from './visualizer-cards-section'
import VisualizerTheoryOfChangeSection from './visualizer-theory-of-change-section'
import VisualizerThreeInARowSection from './visualizer-three-in-a-row-section'
import VisualizerGridCardsSection from './visualizer-grid-cards-section'
import VisualizerScrollingBlocksSection from './visualizer-scrolling-blocks-section'
import VisualizerStackedWithStickyAnimationSection from './visualizer-stacked-with-sticky-animation-section'
import VisualizerTextBubbleSection from './visualizer-text-bubble-section'
import VisualizerPlasticCalculatorSection from './visualizer-plastic-calculator-section'
import VisualizerWidgetGridSection from './visualizer-widget-grid-section'

interface IProps {
    section: IPageSectionVisualizer
    uiResources?: IUiResources
    className?: string
}

const PageSectionVisualizer = ({ section, uiResources, className }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    switch (section.renderType) {
        case 'Collections':
            return <VisualizerCollectionSection section={section} />
        case 'Grid':
            return <VisualizerGridSection section={section} uiResources={uiResources} />
        case 'Slides':
            return <VisualizerSlidesSection section={section} uiResources={uiResources} />
        case 'Timeline':
            return <VisualizerTimelineSection section={section} playMediaLabel={uiResources['playMediaLabel']} />
        case 'Carousel':
            return <VisualizerCarouselSection section={section} />
        case 'Slides with background color':
            return <VisualizerSlidesWithBackgroundColorSection section={section} />
        case 'Before and after':
            return <VisualizerBeforeAndAfterSection section={section} />
        case 'Before and after with text bubbles':
            return <VisualizerTextBubbleSection section={section} className={className} uiResources={uiResources} />
        case 'Stacking cards':
            return <VisualizerStackingCardsSection section={section} />
        case 'Cards':
            return <VisualizerCardsSection section={section} />
        case 'Theory of change':
            return <VisualizerTheoryOfChangeSection section={section} />
        case 'Three in a row':
            return <VisualizerThreeInARowSection section={section} />
        case 'Grid cards':
            return <VisualizerGridCardsSection section={section} />
        case 'Scrolling blocks':
            return <VisualizerScrollingBlocksSection section={section} />
        case 'Stacked with sticky animation':
            return <VisualizerStackedWithStickyAnimationSection section={section} uiResources={uiResources} />
        case 'Plastic Calculator':
            return <VisualizerPlasticCalculatorSection section={section} uiResources={uiResources} />
        case 'Aisle Widget Cards':
            return <VisualizerWidgetGridSection section={section} uiResources={uiResources} />
        default:
            return null
    }
}

export default PageSectionVisualizer
