import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IPicture } from 'interfaces/picture'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import Switch from 'components/switch'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const titleRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header text-1xl lg:text-3.25xl font-medium leading-120% tracking-tight">{children}</h2>
    ),
}

const Picture = ({ picture }: { picture: IPicture }): React.ReactElement => {
    if (!picture) {
        return null
    }

    const { mobileImagesCollection, desktopImagesCollection } = picture

    const mobileImage = mobileImagesCollection?.items[0]
    const desktopImage = desktopImagesCollection?.items[0]

    if (!mobileImage?.url && !desktopImage?.url) {
        return null
    }

    return (
        <picture>
            {desktopImage?.url && (
                <>
                    <source
                        type="image/webp"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={`
                                ${desktopImage.url}?fm=webp&w=1280 1280w,
                                ${desktopImage.url}?fm=webp&w=1600 1600w
                            `}
                        sizes={`(min-width: ${_2xlBreakPoint}) 1280px, 70vw`}
                    />

                    <source
                        type="image/jpeg"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={`
                                ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                ${desktopImage.url}?fm=jpg&w=1600 1600w
                            `}
                        sizes={`(min-width: ${_2xlBreakPoint}) 1280px, 70vw`}
                    />
                </>
            )}

            {mobileImage?.url && (
                <>
                    <source
                        type="image/webp"
                        srcSet={`
                                ${mobileImage.url}?fm=webp&w=420 420w,
                                ${mobileImage.url}?fm=webp&w=640 640w,
                                ${mobileImage.url}?fm=webp&w=800 800w,
                                ${mobileImage.url}?fm=webp&w=1024 1024w
                            `}
                    />

                    <source
                        type="image/jpeg"
                        media={`(min-width: ${lgBreakPoint})`}
                        srcSet={`
                                ${mobileImage.url}?fm=jpg&w=420 420w,
                                ${mobileImage.url}?fm=jpg&w=640 640w,
                                ${mobileImage.url}?fm=jpg&w=800 800w,
                                ${mobileImage.url}?fm=jpg&w=1024 1024w
                            `}
                    />
                </>
            )}

            <img
                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                alt={mobileImage?.description || desktopImage?.description || mobileImage?.title || desktopImage?.title}
                height={mobileImage?.height || desktopImage?.height}
                width={mobileImage?.width || desktopImage?.width}
                loading="lazy"
                className="w-full h-full object-contain"
            />
        </picture>
    )
}

export const VisualizerBeforeAndAfterSection = ({ section }: IProps): React.ReactElement => {
    const [activeState, setActiveState] = React.useState<'before' | 'after'>('before')
    const [hasUserInteracted, setHasUserInteracted] = React.useState(false) // has user toggled the before/after radio
    const [imageContainerEl, setImageContainerEl] = React.useState<HTMLDivElement>()

    const imageContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setImageContainerEl(node)
        }
    }, [])

    const { entry, observer } = useIntersectionObserver({ element: imageContainerEl })

    React.useEffect(() => {
        // autotoggle is stopped after user interaction
        // disconnect observer
        if (hasUserInteracted) {
            observer?.disconnect()
        }
    }, [hasUserInteracted, observer])

    const onFirstOptionSelected = React.useCallback(() => {
        setActiveState('before')
    }, [])

    const onSecondOptionSelected = React.useCallback(() => {
        setActiveState('after')
    }, [])

    const onUserInteracted = React.useCallback(() => {
        setHasUserInteracted(true)
    }, [])

    if (!section) {
        return null
    }

    const { visualsCollection, media, richTitle, ctaLink } = section

    const beforePicture = visualsCollection.items[0]
    const afterPicture = visualsCollection.items[1]
    const hasBothPictures = beforePicture && afterPicture

    return (
        <section className="section--v2">
            {/* section title */}
            {richTitle?.json && <ContentfulRichText renderNode={titleRenderNode} content={richTitle.json} />}

            <div
                className={`grid ${
                    hasBothPictures ? 'lg:grid-cols-[3fr,1.25fr]' : 'lg:grid-cols-[2fr,1fr]'
                } gap-7.5 lg:gap-5 mt-12.5`}
            >
                {/* before and after togglable section */}
                <div className="relative" ref={imageContainerRef}>
                    <div className="grid rounded-2lg overflow-hidden max-h-[300px] lg:max-h-[700px]">
                        {hasBothPictures ? (
                            <>
                                <div
                                    className={`${activeState === 'before' ? '' : 'opacity-0'} col-start-1 row-start-1`}
                                >
                                    <Picture picture={beforePicture} />
                                </div>
                                <div
                                    className={`${activeState === 'after' ? '' : 'opacity-0'} row-start-1 col-start-1`}
                                >
                                    <Picture picture={afterPicture} />
                                </div>
                            </>
                        ) : (
                            <Picture picture={beforePicture || afterPicture} />
                        )}
                    </div>

                    {/* toggle inputs */}
                    {hasBothPictures && (
                        <Switch
                            firstOptionLabel={beforePicture?.title}
                            secondOptionLabel={afterPicture?.title}
                            className="!absolute left-5 bottom-5"
                            onFirstOptionSelected={onFirstOptionSelected}
                            onSecondOptionSelected={onSecondOptionSelected}
                            onUserInteracted={onUserInteracted}
                            autoSwitch={entry?.isIntersecting && !hasUserInteracted}
                        />
                    )}
                </div>

                {/* side card */}
                {media?.url && (
                    <div className="h-full">
                        <div className="rounded-2lg h-full overflow-hidden bg-white flex flex-col justify-between px-5 py-6 space-y-7 lg:max-h-[700px]">
                            {/* card title */}
                            <div className="px-4 py-2">
                                <div className="text-2xl xl:text-3xl font-medium tracking-tight">{media.title}</div>
                                <p className="leading-140% text-lg mt-4 tracking-tight">{media.description}</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <picture>
                                    <source type="image/webp" srcSet={media.url + '?fm=webp&w=400 400w'} />
                                    <source type="image/jpeg" srcSet={media.url + '?fm=jpg&w=400 400w'} />

                                    <img
                                        src={media.url + '?fm=jpg&w=400'}
                                        height={media.height}
                                        width={media.width}
                                        loading="lazy"
                                        className="mx-auto"
                                        alt={media.title}
                                    />
                                </picture>
                                {/* cta */}
                                <Link
                                    href={ctaLink?.href ?? ''}
                                    className="button button-secondary hover:bg-black hover:text-white transition-colors duration-200 self-start w-full mt-4"
                                >
                                    {ctaLink.name}
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </section>
    )
}
