/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import ArrowRightIcon from 'icons/arrow-right'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { colors } from 'theme.cjs'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import RangeSlider from 'components/range-slider/range-slider'
import ArrowNavigation from 'components/arrow-navigation'
import useSmoothScrollSupportDetection from 'hooks/useSmoothScrollSupportDetection'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg
const cardMobileWidth = 150
const cardDesktopWidth = 275
const rangeInputMax = 100

const titleRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <h2>{children}</h2>,
}
const cartTitleRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <div className="font-bold leading-120%">{children}</div>,
}

export const VisualizerScrollingBlocksSection = ({ section }: IProps): React.ReactElement => {
    const [scrollableContainerEl, setScrollableContainerEl] = React.useState<HTMLDivElement>()
    const scrollableContainerRef = React.useCallback((node) => {
        if (node !== null) {
            setScrollableContainerEl(node)
        }
    }, [])

    const [doesSectionHaveScrollbar, setDoesSectionHaveScrollbar] = React.useState(false)
    const [rangeSliderPosition, setRangeSliderPosition] = React.useState(0)

    const isSmoothScrollSupported = useSmoothScrollSupportDetection()
    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        if (!scrollableContainerEl) {
            return
        }

        setDoesSectionHaveScrollbar(scrollableContainerEl.scrollWidth > scrollableContainerEl.clientWidth)

        let timer: NodeJS.Timeout

        const windowResizeHandler = () => {
            clearTimeout(timer)

            timer = setTimeout(() => {
                setDoesSectionHaveScrollbar(scrollableContainerEl.scrollWidth > scrollableContainerEl.clientWidth)
            }, 1000)
        }

        window.addEventListener('resize', windowResizeHandler)

        return () => {
            window.removeEventListener('resize', windowResizeHandler)
        }
    }, [scrollableContainerEl])

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const scrollLeft = e.currentTarget.scrollLeft
        const scrollWidth = e.currentTarget.scrollWidth
        const clientWidth = e.currentTarget.clientWidth

        const newRangeSliderPosition = (scrollLeft * rangeInputMax) / (scrollWidth - clientWidth)
        setRangeSliderPosition(newRangeSliderPosition)
    }

    const handleRangeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!scrollableContainerEl) {
            return
        }

        const rangeValue = Number(e.target.value)
        const rangeMax = Number(e.target.max)

        const clientWidth = scrollableContainerEl.clientWidth
        const scrollWidth = scrollableContainerEl.scrollWidth

        // scroll left is range multiplied by total horizontal scroll
        const scrollLeft = (rangeValue * (scrollWidth - clientWidth)) / rangeMax
        scrollableContainerEl.scrollLeft = scrollLeft
    }

    if (!section) {
        return null
    }

    const { visualsCollection, richTitle } = section

    const visuals = visualsCollection.items

    return (
        <section className="section--v2">
            <div className="flex justify-between">
                {/* section title */}
                <ContentfulRichText
                    content={richTitle.json}
                    renderNode={titleRenderNode}
                    className="lg:hidden text-1xl leading-120% font-medium tracking-tight"
                    ignoreMarkdownStyles
                />

                {/* navigation arrow buttons */}
                {isMobileScreen && (
                    <ArrowNavigation scrollableContainerEl={scrollableContainerEl} cardWidth={cardMobileWidth} />
                )}
            </div>
            <div
                ref={scrollableContainerRef}
                onScroll={handleScroll}
                className="overflow-auto scrollbar-hidden bleed-scroll--make_element_flex flex"
            >
                <div
                    className={`grid grid-flow-col gap-4 lg:gap-5 mt-5 lg:mt-0 ${
                        isSmoothScrollSupported ? 'snap-x lg:snap-none' : ''
                    }`}
                >
                    {visuals.map((visual) => {
                        const {
                            mobileImagesCollection,
                            desktopImagesCollection,
                            associatedColors,
                            description,
                            richDescription,
                            cta,
                            sys,
                        } = visual

                        const mobileImage = mobileImagesCollection.items[0]
                        const desktopImage = desktopImagesCollection.items[0]

                        return (
                            <Link
                                href={cta.href}
                                key={sys.id}
                                style={{
                                    backgroundColor: associatedColors,
                                    ['--cardMobileWidth' as string]: cardMobileWidth + 'px',
                                    ['--cardDesktopWidth' as string]: cardDesktopWidth + 'px',
                                }}
                                className="rounded-2lg overflow-hidden w-[var(--cardMobileWidth)] lg:w-[var(--cardDesktopWidth)] py-4 px-6 flex flex-col lg:flex-row justify-between relative snap-center lg:snap-align-none group"
                            >
                                {richDescription?.json && (
                                    <ContentfulRichText
                                        content={richDescription.json}
                                        renderNode={cartTitleRenderNode}
                                        className="flex-shrink-0"
                                        ignoreMarkdownStyles
                                    />
                                )}

                                {(mobileImage?.url || desktopImage?.url) && (
                                    <picture className="mt-3 -mb-4 relative left-6 lg:static">
                                        {desktopImage && (
                                            <>
                                                <source
                                                    type="image/webp"
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    srcSet={desktopImage.url + '?fm=webp&w=600'}
                                                />
                                                <source
                                                    type="image/jpeg"
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    srcSet={desktopImage.url + '?fm=jpg&w=600'}
                                                />
                                            </>
                                        )}

                                        {mobileImage && (
                                            <>
                                                <source type="image/webp" srcSet={mobileImage.url + '?fm=webp&w=600'} />
                                                <source type="image/jpeg" srcSet={mobileImage.url + '?fm=jpg&w=600'} />
                                            </>
                                        )}

                                        <img
                                            width={mobileImage?.width || desktopImage?.width}
                                            height={mobileImage?.height || desktopImage?.height}
                                            alt={description || mobileImage?.description || desktopImage?.description}
                                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=600'}
                                            className="w-full h-full object-cover"
                                            loading="lazy"
                                        />
                                    </picture>
                                )}

                                <div className="rounded-full w-7 h-7 bg-white flex justify-center items-center absolute left-6 bottom-4 group-hover:bg-black transition-colors duration-200">
                                    <ArrowRightIcon
                                        stroke={colors.mineshaft}
                                        strokeWidth={2}
                                        pathClassName="group-hover:stroke-white transition-colors duration-200"
                                    />
                                </div>
                            </Link>
                        )
                    })}
                </div>
            </div>

            {doesSectionHaveScrollbar && !isMobileScreen && (
                <RangeSlider
                    label=""
                    aria-hidden
                    className="w-full mt-11"
                    onChange={handleRangeInputChange}
                    max={rangeInputMax}
                    value={rangeSliderPosition}
                    step="0.1"
                />
            )}
        </section>
    )
}
