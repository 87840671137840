import * as React from 'react'
import { colors } from 'theme.cjs'
import styles from './style.module.css'

interface IProps {
    progress: number
    progressColor?: string
    progressTrackColor?: string
    className?: string
    animate?: boolean
}

const ProgressBar = ({
    progress,
    progressColor = colors.slateBlue,
    progressTrackColor = 'transparent',
    className = '',
    animate,
}: IProps): React.ReactElement => {
    return (
        <div
            className={`h-1 ${className} ${styles['progress-bar']} ${animate ? styles['progress-bar--animated'] : ''}`}
            style={{
                // set progress between 0 and 100
                ['--progress' as string]: Math.max(0, Math.min(100, progress)) + '%',
                ['--progressColor' as string]: progressColor,
                ['--progressTrackColor' as string]: progressTrackColor,
            }}
        />
    )
}

export default ProgressBar
