import * as React from 'react'

/**
 * Checks and returns if browser supports smooth scrolling
 * @returns boolean
 */
const useSmoothScrollSupportDetection = (): boolean => {
    const [browserSupportsSmoothScroll, setBrowserSupportsSmoothScroll] = React.useState(true)

    React.useEffect(() => {
        setBrowserSupportsSmoothScroll(window.CSS.supports('scroll-behavior', 'smooth'))
    }, [])

    return browserSupportsSmoothScroll
}

export default useSmoothScrollSupportDetection
