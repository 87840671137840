/* eslint-disable react/display-name */
import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import twConfig from 'tailwind-light-config'
import {
    visualizerSectionMissionInternalName,
    visualizerSectionOurMissionInternalName,
    visualizerSectionOurProgressInternalName,
    visualizerSectionProgressInternalName,
} from 'constants/contentful'
const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
}

const titleRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="section--header section--header-max-width mx-auto text-4xl lg:text-5xl tracking-tight lg:font-medium text-center">
            {children}
        </h2>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="font-bold text-base tracking-wider text-center">{children}</p>
    ),
}

const cardDescriptionRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase font-medium leading-140% lg:text-base">{children}</p>
    ),
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['_2xl']

export const VisualizerCardsSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { subHeader, richTitle, visualsCollection, backgroundColor, internalName } = section

    // Target and CVS pages use different sections -- some internal names are identical, some are not.
    // The team wanted to use different spacing, text color, etc between Target and CVS pages.

    const isTargetSection =
        internalName === visualizerSectionMissionInternalName ||
        internalName === visualizerSectionOurProgressInternalName

    const isCVSSection =
        internalName === visualizerSectionOurMissionInternalName ||
        internalName === visualizerSectionProgressInternalName

    // otherwise, section--v2
    const visuals = visualsCollection?.items ?? []

    return (
        <section
            className={`py-10 ${isTargetSection ? 'section--v2--sm' : 'section--v2'}`}
            style={{ background: backgroundColor }}
        >
            {subHeader?.json && (
                <ContentfulRichText
                    content={subHeader.json}
                    renderNode={subHeaderRenderNode}
                    className={isCVSSection ? 'text-white' : ''}
                />
            )}

            {richTitle?.json && (
                <ContentfulRichText
                    content={richTitle?.json}
                    renderNode={titleRenderNode}
                    className={`mb-4 ${isCVSSection ? 'text-white' : ''}`}
                />
            )}

            {visuals.length > 0 && (
                <div
                    className={`grid gap-5 py-4 lg:grid-flow-col lg:auto-cols-fr ${
                        visuals.length < 4 ? 'xl:auto-cols-[25rem] justify-center' : ''
                    }`}
                >
                    {visuals.map((visual) => {
                        const { sys, mobileImagesCollection, desktopImagesCollection, title, richDescription } = visual

                        const mobileImage = mobileImagesCollection?.items?.[0]
                        const desktopImage = desktopImagesCollection?.items?.[0]

                        return (
                            <div className="bg-white rounded-2lg p-5 flex flex-col justify-between" key={sys?.id}>
                                {/* title + description */}
                                <div>
                                    <div className="text-3xl font-medium tracking-tight leading-110% lg:text-3.25xl lg:leading-120%">
                                        {title}
                                    </div>

                                    {richDescription?.json && (
                                        <ContentfulRichText
                                            content={richDescription.json}
                                            renderNode={cardDescriptionRenderNode}
                                            className="mt-2"
                                            parseSuperscriptTag
                                        />
                                    )}
                                </div>

                                {/* image */}
                                {(mobileImage?.url || desktopImage?.url) && (
                                    <picture>
                                        {/* desktop */}
                                        {desktopImage?.url && (
                                            <>
                                                {desktopImage.url.endsWith('.svg') && (
                                                    <source type="image/svg+xml" srcSet={desktopImage.url} />
                                                )}

                                                <source
                                                    type="image/webp"
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    srcSet={`
                                                        ${desktopImage.url}?fm=webp&w=640 640w
                                                    `}
                                                    sizes={`(min-width: ${_2xlBreakPoint}) ${_2xlBreakPoint / 3}, 30vw`}
                                                />

                                                <source
                                                    type="image/jpeg"
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    srcSet={`
                                                        ${desktopImage.url}?fm=jpg&w=640 640w
                                                    `}
                                                    sizes={`(min-width: ${_2xlBreakPoint}) ${_2xlBreakPoint / 3}, 30vw`}
                                                />
                                            </>
                                        )}

                                        {mobileImage?.url && (
                                            <>
                                                {mobileImage.url.endsWith('.svg') && (
                                                    <source type="image/svg+xml" srcSet={mobileImage.url} />
                                                )}

                                                <source
                                                    type="image/webp"
                                                    srcSet={`
                                                        ${mobileImage.url}?fm=webp&w=640 640w
                                                    `}
                                                />

                                                <source
                                                    type="image/jpeg"
                                                    srcSet={`
                                                        ${mobileImage.url}?fm=jpg&w=640 640w
                                                    `}
                                                />
                                            </>
                                        )}

                                        <img
                                            src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=640'}
                                            alt={
                                                mobileImage?.description ||
                                                desktopImage?.description ||
                                                mobileImage?.title ||
                                                desktopImage?.title
                                            }
                                            height={mobileImage?.height || desktopImage?.height}
                                            width={mobileImage?.width || desktopImage?.width}
                                            loading="lazy"
                                            className="mt-2.5 lg:h-auto lg:mt-10 mb-2.5 lg:mb-5 mx-auto"
                                        />
                                    </picture>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </section>
    )
}
