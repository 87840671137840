import * as React from 'react'
import Video from 'components/video/video'
import { IVideo } from 'interfaces/media'
import globalManifest from 'data/global-manifest.json'
import { IconButton } from 'components/buttons/button'
import PlayIcon from 'icons/play-icon'
import PauseIcon from 'icons/pause-icon'
import { colors } from 'theme.cjs'

interface IProps {
    video: IVideo
    className?: string
    playButtonLabel: string
    pauseButtonLabel: string
}

const { resources: globalUIResources } = globalManifest ?? {}

export const VisualizerGridVideo = ({
    video,
    className = '',
    playButtonLabel,
    pauseButtonLabel,
}: IProps): React.ReactElement => {
    const [isPlaying, setIsPlaying] = React.useState(false)
    const [videoEl, setVideoEl] = React.useState<HTMLVideoElement>()

    const videoElRef = React.useCallback((node) => {
        if (node !== null) {
            setVideoEl(node)
        }
    }, [])

    React.useEffect(() => {
        if (!videoEl) {
            return
        }

        const onPlay = () => {
            setIsPlaying(true)
        }

        const onPause = () => {
            setIsPlaying(false)
        }

        videoEl.addEventListener('play', onPlay)
        videoEl.addEventListener('pause', onPause)

        return () => {
            videoEl.removeEventListener('play', onPlay)
            videoEl.removeEventListener('pause', onPause)
        }
    }, [videoEl])

    if (!video) {
        return null
    }

    const togglePlay = () => {
        if (isPlaying) {
            videoEl?.pause()
        } else {
            videoEl?.play()
        }
    }

    return (
        <div className={`relative group ${className}`}>
            <Video ref={videoElRef} width="100%">
                <source src={video.url} type={video.contentType} />
                <span>{globalUIResources?.['browserDoesNotSupportVideoMessage']?.value}</span>
            </Video>

            {/* play/pause button */}
            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                <IconButton
                    label={isPlaying ? pauseButtonLabel : playButtonLabel}
                    onClick={togglePlay}
                    className={`rounded-full border-4 border-white h-24 w-24 flex justify-center items-center ${
                        isPlaying ? 'opacity-0 group-hover:opacity-100 transition-opacity' : ''
                    }`}
                >
                    {isPlaying ? (
                        <PauseIcon fill={colors.white} className="scale-125" />
                    ) : (
                        <PlayIcon fill={colors.white} className="scale-125" />
                    )}
                </IconButton>
            </div>
        </div>
    )
}
