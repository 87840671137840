/* eslint-disable react/display-name */
import * as React from 'react'
import GenericForm from 'components/generic-form/generic-form'
import ContentfulRichText from 'components/rich-text'
import TitleWithColonIcon from 'components/title-with-colon-icon/title-with-colon-icon'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import { IPageSectionForm } from 'interfaces/page-section-form'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { BLOCKS } from '@contentful/rich-text-types'

interface IProps {
    section: IPageSectionForm
    className?: string
}

const lgBreakPoint = Number(screens['lg'].replace('px', ''))

const headerRenderNodeOption = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-1xl lg:text-2xl tracking-tight">{children}</p>,
}

const paragraphRenderNodeOption = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="mt-2.5 lg:mt-5 text-xbase lg:text-lg tracking-tight leading-140%">{children}</p>
    ),
}

export const FormSection = ({ section, className = '' }: IProps): React.ReactElement => {
    const { media, mobileMedia, header, paragraph, subHeader, alignImage, renderType, siteLocation } = section ?? {}

    const showSubheader = Boolean(subHeader?.json)

    return (
        <section className={`section--v2 ${className}`}>
            <div
                className={`flex flex-col rounded-2lg overflow-hidden relative ${
                    alignImage === DIRECTION_HORIZONTAL.RIGHT ? 'lg:flex-row-reverse' : 'lg:flex-row'
                }`}
            >
                {/* cover image */}
                {(media || mobileMedia) && (
                    <picture className="lg:w-1/2">
                        {mobileMedia && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                ${mobileMedia.url}?fm=webp&w=768
                            `}
                                    media={`(max-width: ${lgBreakPoint - 1}px)`}
                                />
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                ${mobileMedia.url}?fm=jpg&w=768
                            `}
                                    media={`(max-width: ${lgBreakPoint - 1}px)`}
                                />
                            </>
                        )}
                        {media && (
                            <>
                                <source
                                    type="image/webp"
                                    srcSet={`
                                ${media.url}?fm=webp&w=768
                            `}
                                    media={`(min-width: ${lgBreakPoint}px)`}
                                />
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                ${media.url}?fm=jpg&w=768
                            `}
                                    media={`(max-width: ${lgBreakPoint}px)`}
                                />
                            </>
                        )}
                        <img
                            src={`${mobileMedia.url || media.url}?w=768`}
                            alt={mobileMedia.title || media.title}
                            height={mobileMedia.height || media.height}
                            width={mobileMedia.url || media.url}
                            className={`h-full object-cover w-full ${
                                renderType === 'Fixed inputs' ? 'max-h-[335px] lg:max-h-[none] ' : 'lg:max-h-[500px]'
                            }`}
                            loading="lazy"
                        />
                    </picture>
                )}

                {/* form */}
                <div className="p-5 lg:p-10 lg:w-1/2 bg-white flex flex-col min-h-[350px]">
                    {showSubheader && (
                        <>
                            <TitleWithColonIcon content={subHeader.json} />
                        </>
                    )}
                    <hr className="bg-black opacity-30 mt-2.5" />
                    <div className="flex flex-col justify-between mt-2.5 lg:mt-6 flex-grow">
                        <div>
                            <ContentfulRichText renderNode={headerRenderNodeOption} content={header?.json} />
                            <ContentfulRichText renderNode={paragraphRenderNodeOption} content={paragraph?.json} />
                        </div>

                        <div className="mt-5 lg:mt-6 w-full">
                            <GenericForm renderType={renderType} form={section} formLocation={siteLocation} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
