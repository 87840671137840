/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import Switch from 'components/switch'

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg
const _2xlBreakPoint = screens['2xl']

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-1xl lg:text-3.25xl text-center font-medium tracking-tight leading-120%">{children}</p>
    ),
}

export const VisualizerTheoryOfChangeSection = ({ section }: IProps): React.ReactElement => {
    const [activeVisualIndex, setActiveVisualIndex] = React.useState(0)

    if (!section) {
        return null
    }

    const { richTitle, visualsCollection, backgroundColor } = section

    const visuals = visualsCollection.items ?? []

    return (
        <section className="section--v2 lg:px-24 xl:px-40" style={{ backgroundColor }}>
            {/* header */}
            {richTitle?.json && (
                <ContentfulRichText
                    content={richTitle.json}
                    className="max-w-3xl mx-auto"
                    renderNode={headerRenderNode}
                />
            )}

            {visuals.length > 0 && (
                <div className="mt-15">
                    {/* toggle */}
                    <div className="flex justify-center">
                        <Switch
                            firstOptionLabel={visuals[0]?.title}
                            secondOptionLabel={visuals[1]?.title}
                            onFirstOptionSelected={() => {
                                setActiveVisualIndex(0)
                            }}
                            onSecondOptionSelected={() => {
                                setActiveVisualIndex(1)
                            }}
                        />
                    </div>

                    {/* visuals */}
                    <div className="mt-16 lg:mt-28 grid relative">
                        {visuals.map((visual, index) => {
                            const { sys, mobileImagesCollection, desktopImagesCollection } = visual
                            const mobileImage = mobileImagesCollection?.items?.[0]
                            const desktopImage = desktopImagesCollection?.items?.[0]

                            const isActive = index === activeVisualIndex

                            if (!mobileImage?.url || !desktopImage?.url) {
                                return null
                            }
                            return (
                                <picture key={sys?.id} className="col-start-1 col-end-2 row-start-1 row-end-2">
                                    {/* desktop image */}
                                    {desktopImage?.url && (
                                        <>
                                            {desktopImage.url.endsWith('.svg') && (
                                                <source
                                                    type="image/svg+xml"
                                                    media={`(min-width: ${lgBreakPoint})`}
                                                    srcSet={desktopImage.url}
                                                />
                                            )}

                                            <source
                                                type="image/webp"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={`
                                            ${desktopImage.url}?fm=webp&w=1024 1024w,
                                            ${desktopImage.url}?fm=webp&w=1280 1280w,
                                            ${desktopImage.url}?fm=webp&w=1600 1600w,
                                            ${desktopImage.url}?fm=webp&w=2000 2000w
                                        `}
                                                sizes={`(min-width: ${_2xlBreakPoint}) 1600px, 90vw`}
                                            />

                                            <source
                                                type="image/jpeg"
                                                media={`(min-width: ${lgBreakPoint})`}
                                                srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=1024 1024w,
                                            ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                            ${desktopImage.url}?fm=jpg&w=1600 1600w,
                                            ${desktopImage.url}?fm=jpg&w=2000 2000w
                                        `}
                                                sizes={`(min-width: ${_2xlBreakPoint}) 1600px, 90vw`}
                                            />
                                        </>
                                    )}

                                    {/* mobile image */}
                                    {mobileImage?.url && (
                                        <>
                                            {mobileImage.url.endsWith('.svg') && (
                                                <source type="image/svg+xml" srcSet={mobileImage.url} />
                                            )}

                                            <source
                                                type="image/webp"
                                                srcSet={`
                                            ${mobileImage.url}?fm=webp&w=320 320w,
                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                            ${mobileImage.url}?fm=webp&w=820 820w,
                                            ${mobileImage.url}?fm=webp&w=1024 1024w
                                        `}
                                            />

                                            <source
                                                type="image/jpeg"
                                                srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=320 320w,
                                            ${mobileImage.url}?fm=jpg&w=640 640w,
                                            ${mobileImage.url}?fm=jpg&w=820 820w,
                                            ${mobileImage.url}?fm=jpg&w=1024 1024w
                                        `}
                                            />
                                        </>
                                    )}

                                    <img
                                        src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=1024'}
                                        alt={
                                            mobileImage?.title ||
                                            mobileImage?.description ||
                                            desktopImage?.title ||
                                            desktopImage?.description
                                        }
                                        height={mobileImage?.height || desktopImage?.height}
                                        width={mobileImage?.width || desktopImage?.width}
                                        className={`block mx-auto w-full lg:w-full transition-opacity ${
                                            isActive ? '' : 'opacity-0 absolute -z-10 left-0 top-0'
                                        }`}
                                        loading="lazy"
                                    />
                                </picture>
                            )
                        })}
                    </div>
                </div>
            )}
        </section>
    )
}
