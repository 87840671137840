import useDrugPrice, { DEFAULT_DRUG_DAYS_SUPPLY } from 'hooks/useDrugPrice'
import React, { ChangeEvent, useState } from 'react'
import { IOnboardingFormProps } from 'components/onboarding-flow-steps/utils'
import { IUiResources } from 'interfaces/ui-resource'
import { ICommonCopy, ICommonCopyDict } from 'interfaces/common-copy'
import ArrowRightIcon from 'icons/arrow-right'
import { IOnboardingFlowDetails } from 'interfaces/onboarding-flow'
import { TFormSubmissionStates } from 'components/products/medicine-product/rx-medicine-product'
import styles from './style.module.css'
import Button from 'components/buttons/button'
import { setItemInSS } from 'lib/util/storage'
import { rxSignupDetailsCtxStorageKey } from 'misc-variables'
import { rxContextFlowSlug } from 'constants/url'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { wordInsideCurlyBracketsRegexp } from 'lib/util/regexps'
import MagnifyingGlassIcon from 'icons/magnifying-glass'
import { useRouter } from 'next/router'
import { IFormInput } from 'interfaces/form-input'
import ErrorMessage from 'components/error-message'
import {
    generatePrescriptionId,
    onboardingFlowInitialState,
} from 'context/onboarding-flow-context/onboarding-flow-reducer'
import useDrugSelection from 'hooks/useDrugSelection'
import { ErrorBoundary } from '@sentry/nextjs'
import useSubstanceSearch from 'hooks/useSubstanceSearch'
import { ISubstance } from 'interfaces/substance'
import { getRequestedQuantityInPrescriptionForm } from '@/lib/util/prescription'
import { PRESCRIPTION_QUANTITY_INPUT_METHOD } from 'enum/prescription-quantity-input-method'
import { RenderNode } from '@contentful/rich-text-react-renderer'
import { PriceSavingsCallout } from 'components/onboarding-flow-steps/price-savings-callout'

interface IProps extends IOnboardingFormProps {
    uiResources: IUiResources
    commonCopy: ICommonCopyDict
    searchInput: IFormInput
    showIconsAndCopy?: boolean
    setIsSearchActive?: React.Dispatch<React.SetStateAction<boolean>>
}

const radioInputClassName = 'appearance-none focus-visible:!ring-0 peer'
const radioInputLabelClassName = `
    relative
    px-6 py-1.5 lg:py-2.5 font-bold capitalize flex-shrink-0 rounded-full cursor-pointer
    border border-black ring-gray text-black
    peer-checked:bg-black peer-checked:text-white
    peer-focus-visible:ring
    hover:ring-2 hover:ring-black hover:text-black
    peer-disabled:border-gray-medium
    peer-disabled:text-gray peer-disabled:cursor-not-allowed
    peer-disabled:hover:border-gray-medium peer-disabled:hover:text-gray peer-disabled:hover:ring-0
    peer-checked:peer-disabled:bg-transparent peer-checked:peer-disabled:text-gray
    peer-checked:peer-disabled:hover:text-gray peer-checked:peer-disabled:hover:ring-0
    peer-disabled:after:block peer-disabled:after:absolute peer-disabled:after:left-0 peer-disabled:after:top-1/2 peer-disabled:after:h-px peer-disabled:after:w-full peer-disabled:after:bg-gray-medium peer-disabled:after:-translate-y-px
`
const errorMessageClassName = 'form-error block mt-1'

const pillsPerDayInputMin = 0
const pillsPerDayInputMax = 5
const rangeInputThumbHeight = 44
const rangeInputThumbWidth = 44
const minimumTotalPills = 1

const drugStrengthInputName = 'drug_strength'
const pillsPerDayInputName = 'pills_per_day'
const daySupplyInputName = 'day_supply'
const totalPillCountInputName = 'total_pills_direct_input'
const drugFormInputName = 'drug_form'
const pouchOrBottleInputName = 'include_bottle'

const iconResourceKeys = [
    'pharmacyTeamHeartIconAndLabel',
    'carbonNeutralShippingIconAndLabel',
    'refillsAndRenewalsIconAndLabel',
    'singleUsePlasticLabelAndIcon',
]

export interface IFormErrors {
    [daySupplyInputName]?: string
    [drugStrengthInputName]?: string
    [pillsPerDayInputName]?: string
    [drugFormInputName]?: string
    [pouchOrBottleInputName]?: string
    [totalPillCountInputName]?: string
}

function ErrorFallback({ message }: { message: ICommonCopy }) {
    return (
        <div className="alert">
            <ContentfulRichText content={message.value.json} className="*:mb-0" ignoreMarkdownStyles />
        </div>
    )
}

export function RxSearchFormWithErrorBoundary(props: IProps): React.ReactElement {
    return (
        <ErrorBoundary fallback={<ErrorFallback message={props.commonCopy['rxSearchFormErrorBoundaryMessage']} />}>
            <RxSearchForm {...props} />
        </ErrorBoundary>
    )
}

const RxSearchForm = ({
    uiResources,
    commonCopy,
    searchInput,
    showIconsAndCopy = false,
    setIsSearchActive,
}: IProps): React.ReactElement => {
    const router = useRouter()
    const [searchTerm, setSearchTerm] = useState('')
    const [substance, setSubstance] = React.useState<ISubstance>()

    const { substances, loadingSubstances, substancesLoadingError, refetchSubstances } = useSubstanceSearch({
        name: searchTerm, // pass undefined to avoid searching empty string
        limit: 20,
        order_by: 'name',
    })

    const { selectedDrug, setDrug, selectedDrugFormName, setDrugFormName, drugsGroupedByForm } =
        useDrugSelection(substance)

    const [pillsPerDayInput, setPillsPerDayInput] = React.useState(1)

    const [daySupply, setDaySupply] = React.useState<number>()

    const [daySupplyInputMethod, setDaySupplyInputMethod] = React.useState<PRESCRIPTION_QUANTITY_INPUT_METHOD>(
        PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE,
    )

    const pouchOrBottle = 'bottle'

    const [formSubmissionState, setFormSubmissionState] = React.useState<TFormSubmissionStates>('idle')

    const searchDrawerFormRef = React.useRef<HTMLFormElement>()

    const formId = 'rx-form'

    const pillsPerDayInputNumber = Number(pillsPerDayInput)

    const [rangeInputEl, setRangeInputEl] = React.useState<HTMLInputElement>()
    const rangeInputRef = React.useCallback((node) => {
        if (node !== null) {
            setRangeInputEl(node)
        }
    }, [])

    const rangeInputThumbTextPositionLeft =
        ((pillsPerDayInputNumber - pillsPerDayInputMin) / (pillsPerDayInputMax - pillsPerDayInputMin)) *
        (rangeInputEl?.clientWidth - rangeInputThumbWidth / 2 - rangeInputThumbWidth / 2)

    const [totalPillCountInput, setTotalPillCountInput] = React.useState<string>('1')

    const daySupplyOptions = React.useMemo(() => {
        return [
            {
                label: uiResources['rxPdpFormThirtyDaySupplyOptionLabel'].value,
                id: uiResources['rxPdpFormThirtyDaySupplyOptionLabel'].value,
                value: 30,
            },
            {
                label: uiResources['rxPdpFormSixtyDaySupplyOptionLabel'].value,
                id: uiResources['rxPdpFormSixtyDaySupplyOptionLabel'].value,
                value: 60,
            },
            {
                label: uiResources['rxPdpFormNinetyDaySupplyOptionLabel'].value,
                id: uiResources['rxPdpFormNinetyDaySupplyOptionLabel'].value,
                value: 90,
            },
        ]
    }, [uiResources])

    const handleSearchTermOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value
        setSearchTerm(query)

        const isActive = query.trim() !== ''
        setIsSearchActive?.(isActive)

        // Clear selected substance when the search term changes
        setSubstance(null)
    }

    const substanceErrorMessageRenderNode: RenderNode = React.useMemo(
        () => ({
            [BLOCKS.PARAGRAPH]: (_node, children): React.ReactNode => {
                // renders word/s inside {curly brace}
                // eg: contact our {customer care} => contact our <button>customer care</button>
                return React.Children.map(children, (child) => {
                    if (typeof child !== 'string') {
                        return child
                    }

                    const matchedWord = child.match(wordInsideCurlyBracketsRegexp)
                    if (!matchedWord) {
                        return child
                    }

                    const templateSplit: React.ReactNode[] = child.split(wordInsideCurlyBracketsRegexp)

                    const matchedWordExcludingBraces = matchedWord?.[0]?.replace('{', '').replace('}', '')

                    // insert the button at index 1 which is the position of the
                    // word/s inside curly braces
                    templateSplit.splice(
                        1,
                        0,
                        <button type="button" className="underline" onClick={() => refetchSubstances()}>
                            {matchedWordExcludingBraces}
                        </button>,
                    )

                    return templateSplit
                })
            },
        }),
        [refetchSubstances],
    )

    const showResults = searchTerm !== ''

    const totalPillCountInputNumber = Number(totalPillCountInput)

    const drugQuantity = getRequestedQuantityInPrescriptionForm({
        daySupply,
        pillsPerDay: pillsPerDayInput,
        requestedQuantityDirect: totalPillCountInputNumber,
        daySupplyInputMethod: daySupplyInputMethod,
    })

    const { drugPrices, drugPriceLoadingError, loadingDrugPrices, refetchDrugPrices } = useDrugPrice({
        substanceId: substance?.id,
        quantity: drugQuantity,
        daysSupply:
            daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE
                ? daySupply || DEFAULT_DRUG_DAYS_SUPPLY
                : undefined,
    })

    const priceOfSelectedDrug = selectedDrug
        ? drugPrices?.find((drugPrice) => {
              return drugPrice.drug_id === selectedDrug.id
          })
        : null

    const drugFormKeys: string[] = !!drugsGroupedByForm ? Object.keys(drugsGroupedByForm) : []

    const [touchedFormInputs, setTouchedFormInputs] = React.useState<string[]>([])

    const formErrors: IFormErrors = {}

    // validate
    if (isNaN(totalPillCountInputNumber) || totalPillCountInputNumber < minimumTotalPills) {
        formErrors[totalPillCountInputName] = uiResources['rxPdpFormPillsError'].value
    }

    if (daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE && pillsPerDayInput <= 0) {
        formErrors[pillsPerDayInputName] = uiResources['rxPdpFormPillsError'].value
    }

    if (!selectedDrugFormName) {
        formErrors[drugFormInputName] = uiResources['rxPdpFormMedicationTypeError'].value
    }

    if (!selectedDrug) {
        formErrors[drugStrengthInputName] = uiResources['rxPdpFormDosageError'].value
    }

    if (daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE && !daySupply) {
        formErrors[daySupplyInputName] = uiResources['rxPdpFormDaySupplyError'].value
    }

    if (!pouchOrBottle) {
        formErrors[pouchOrBottleInputName] = uiResources['rxPdpFormPouchOrBottleError'].value
    }

    const totalFormErrors = Object.keys(formErrors).length

    const handleFormSubmit = async () => {
        if (loadingDrugPrices) {
            return
        }

        const searchFormData = new FormData(searchDrawerFormRef.current)

        setFormSubmissionState('submitting')
        const flowDetails: IOnboardingFlowDetails = {
            ...onboardingFlowInitialState,
            prescriptions: [
                {
                    id: generatePrescriptionId(),
                    day_supply: searchFormData.get('day_supply') as string,
                    drug: selectedDrug,
                    substance,
                    pharmacy: undefined,
                    drug_form: (searchFormData.get('drug_form') as string) || selectedDrug?.details?.drug_form_name,
                    bulk_supply_checked: searchFormData.get('bulk_supply_checked') === 'true',
                    pills_per_day: Number(searchFormData.get('pills_per_day')),
                    total_pills_direct_input: Number(searchFormData.get('total_pills_direct_input')) || 1,
                    pill_quantity_input_method: daySupplyInputMethod,
                    price: priceOfSelectedDrug,
                    transfer_type: undefined,
                },
            ],
        }

        setItemInSS(rxSignupDetailsCtxStorageKey, JSON.stringify(flowDetails))
        router.push(`/flow/${rxContextFlowSlug}` + window.location.search)
    }

    const renderForm = () => {
        if (drugPriceLoadingError) {
            return (
                <ContentfulRichText
                    content={commonCopy['rxOnboardingPriceFetchError'].value.json}
                    renderNode={drugPricesErrorMessageRenderNode}
                    className="text-gray-darker"
                    ignoreMarkdownStyles
                />
            )
        }

        const filteredTradeNames = substance.trade_names.filter(
            (tradeName) => tradeName.toLowerCase() !== substance.name.toLowerCase(),
        )

        return (
            <form
                id={formId}
                ref={searchDrawerFormRef}
                onSubmit={(e) => {
                    e.preventDefault()
                    setTouchedFormInputs([
                        drugFormInputName,
                        drugStrengthInputName,
                        pillsPerDayInputName,
                        daySupplyInputName,
                        pouchOrBottleInputName,
                        totalPillCountInputName,
                    ])

                    if (totalFormErrors === 0 && !loadingDrugPrices) {
                        handleFormSubmit()
                    } else {
                        // let the 'form-error' render
                        setTimeout(() => {
                            const firstErrorElement =
                                searchDrawerFormRef.current.getElementsByClassName?.('form-error')?.[0]
                            firstErrorElement?.scrollIntoView({ block: 'center' })
                        }, 0)
                    }
                }}
                data-cy="rx-search-form"
            >
                <div
                    key={substance.id}
                    className="w-full flex justify-between space-x-2 items-center border-t border-gray-medium py-2.5 group text-left"
                >
                    <div className="flex flex-col">
                        <div className="font-bold text-black text-2xl capitalize">{substance.name}</div>
                        {filteredTradeNames.length > 0 && (
                            <div className="text-gray-darker text-xs">
                                {uiResources['rxMedicineSearchFormGenericForLabel'].value}{' '}
                                <span className="capitalize">
                                    {filteredTradeNames.map((tradeName, index, self) => {
                                        if (index === self.length - 1) {
                                            return tradeName
                                        }

                                        return `${tradeName}, `
                                    })}
                                </span>
                            </div>
                        )}

                        {drugFormKeys.length < 2 ? null : (
                            <fieldset className="mt-4">
                                <legend className="font-bold">
                                    {uiResources['rxPdpFormMedicationTypeLabel'].value}
                                </legend>
                                <div className="flex flex-wrap -mt-3.5 -ml-3.5 pt-3">
                                    {drugFormKeys.map((drugFormName) => {
                                        return (
                                            <div className="flex mt-3.5 ml-3.5" key={drugFormName}>
                                                <input
                                                    type="radio"
                                                    id={drugFormName}
                                                    name={drugFormInputName}
                                                    className={radioInputClassName}
                                                    value={drugFormName}
                                                    checked={drugFormName === selectedDrugFormName}
                                                    onChange={() => {
                                                        setDrugFormName(drugFormName)
                                                        setDrug(null)
                                                        setTouchedFormInputs((tInputs) => {
                                                            // mark drug as untouched whenever drug form changes
                                                            if (tInputs.includes(drugFormInputName)) {
                                                                return tInputs.filter(
                                                                    (tInput) => tInput !== drugStrengthInputName,
                                                                )
                                                            }

                                                            return tInputs
                                                                .filter((tInput) => tInput !== drugStrengthInputName)
                                                                .concat(drugFormInputName)
                                                        })
                                                    }}
                                                    data-cy="drug-form-input"
                                                />{' '}
                                                <label htmlFor={drugFormName} className={radioInputLabelClassName}>
                                                    {drugFormName}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                                {/* error message */}
                                {formErrors[drugFormInputName] && touchedFormInputs.includes(drugFormInputName) && (
                                    <ErrorMessage className={errorMessageClassName}>
                                        {formErrors[drugFormInputName]}
                                    </ErrorMessage>
                                )}
                            </fieldset>
                        )}

                        {/* drug */}
                        <fieldset className="mt-4">
                            <legend className="font-bold text-black">
                                {uiResources['rxPdpFormMedicationStrengthsLabel'].value}
                            </legend>
                            <div className="flex flex-wrap gap-x-2">
                                {drugsGroupedByForm[selectedDrugFormName]?.map((drug) => {
                                    return (
                                        <div key={drug.id} className="flex mt-3.5">
                                            <input
                                                type="radio"
                                                id={drug.id}
                                                name={drugStrengthInputName}
                                                className={radioInputClassName}
                                                value={drug.id}
                                                checked={drug.id === selectedDrug?.id}
                                                onChange={() => {
                                                    setDrug(drug)
                                                    setTouchedFormInputs((tInputs) => {
                                                        if (!tInputs.includes(drugStrengthInputName)) {
                                                            return [...tInputs, drugStrengthInputName]
                                                        }
                                                        if (!daySupply) {
                                                            setDaySupply(undefined)
                                                        }
                                                        return tInputs
                                                    })
                                                }}
                                                data-cy="drug-strength-input"
                                            />{' '}
                                            <label htmlFor={drug.id} className={radioInputLabelClassName}>
                                                {drug.details.drug_strength}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* error message */}
                            {formErrors[drugStrengthInputName] && touchedFormInputs.includes(drugStrengthInputName) && (
                                <ErrorMessage className={errorMessageClassName}>
                                    {formErrors[drugStrengthInputName]}
                                </ErrorMessage>
                            )}
                        </fieldset>

                        {/* pills per day */}
                        <fieldset className="mt-4">
                            <legend className="font-bold text-black">
                                {uiResources['rxPdpFormPillsInputLabel'].value}
                            </legend>
                            <div className="px-5 h-11 mt-3 flex items-center space-x-4 rounded-full border border-gray-medium">
                                <div className="text-gray">{pillsPerDayInputMin}</div>
                                <div className="relative w-full flex items-center">
                                    <input
                                        type="range"
                                        name={pillsPerDayInputName}
                                        min={pillsPerDayInputMin}
                                        max={pillsPerDayInputMax}
                                        step={0.5}
                                        value={pillsPerDayInput}
                                        className={`${styles['input-range']}`}
                                        ref={rangeInputRef}
                                        onInput={(e) => {
                                            setPillsPerDayInput(Number(e.currentTarget.value))
                                        }}
                                        onChange={(e) => {
                                            setPillsPerDayInput(Number(e.target.value))
                                            setTouchedFormInputs((tInputs) => {
                                                if (!tInputs.includes(pillsPerDayInputName)) {
                                                    return [...tInputs, pillsPerDayInputName]
                                                }

                                                return tInputs
                                            })
                                            if (e.target.value === '0') {
                                                setDaySupplyInputMethod(PRESCRIPTION_QUANTITY_INPUT_METHOD.DIRECT)
                                            } else {
                                                setDaySupplyInputMethod(PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE)
                                            }
                                        }}
                                        style={{
                                            ['--thumbWidth' as string]: rangeInputThumbWidth + 'px',
                                            ['--thumbHeight' as string]: rangeInputThumbHeight + 'px',
                                        }}
                                        data-cy="pill-count-range-input"
                                    />

                                    {/* value over thumb */}
                                    <span
                                        style={{
                                            ['--left' as string]: rangeInputThumbTextPositionLeft + 'px',
                                        }}
                                        className="h-11 w-11 flex justify-center items-center pointer-events-none bg-transparent rounded-full text-white absolute left-[calc(var(--left))]"
                                    >
                                        {pillsPerDayInput}
                                    </span>
                                </div>
                                <div className="text-gray">{pillsPerDayInputMax}</div>
                            </div>
                            {/* error message */}
                            {formErrors[pillsPerDayInputName] && touchedFormInputs.includes(pillsPerDayInputName) && (
                                <ErrorMessage className={errorMessageClassName}>
                                    {formErrors[pillsPerDayInputName]}
                                </ErrorMessage>
                            )}
                        </fieldset>

                        {/* day supply */}
                        <fieldset className="mt-4">
                            <legend className="font-bold text-black">
                                {uiResources['rxPdpFormDaySupplyLabel'].value}
                            </legend>
                            <div className="flex flex-wrap -mt-3.5 -ml-3.5 pt-3">
                                {daySupplyOptions.map((supply) => {
                                    return (
                                        <div key={supply.id} className="flex mt-3.5 ml-3.5">
                                            <input
                                                type="radio"
                                                id={supply.id}
                                                name={daySupplyInputName}
                                                value={supply.value}
                                                className={radioInputClassName}
                                                checked={supply.value === daySupply}
                                                onChange={(e) => {
                                                    setDaySupply(Number(e.target.value))
                                                    setTouchedFormInputs((tInputs) => {
                                                        if (!tInputs.includes(daySupplyInputName)) {
                                                            return [...tInputs, daySupplyInputName]
                                                        }

                                                        return tInputs
                                                    })
                                                }}
                                                disabled={
                                                    daySupplyInputMethod !==
                                                    PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE
                                                }
                                                data-cy="day-supply-input"
                                            />{' '}
                                            <label htmlFor={supply.id} className={radioInputLabelClassName}>
                                                <span className="hidden sm:inline">{supply.label}</span>
                                                <span className="sm:hidden">{supply.label.split(' ')[0]}</span>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            {/* error message */}
                            {formErrors[daySupplyInputName] && touchedFormInputs.includes(daySupplyInputName) && (
                                <ErrorMessage className={errorMessageClassName}>
                                    {formErrors[daySupplyInputName]}
                                </ErrorMessage>
                            )}
                        </fieldset>

                        {daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE && (
                            <div className="mt-4 flex justify-center sm:block">
                                <button
                                    className="text-xsm underline"
                                    onClick={() => setDaySupplyInputMethod(PRESCRIPTION_QUANTITY_INPUT_METHOD.DIRECT)}
                                    type="button"
                                    data-cy="enter-manually-button"
                                >
                                    {uiResources['rxPdpFormManualPillInputButtonLabel'].value}
                                </button>
                            </div>
                        )}

                        {/* total pills + type no. of pills per day */}
                        <fieldset
                            className={
                                daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE
                                    ? 'hidden'
                                    : 'mt-4'
                            }
                        >
                            <legend className="font-bold">{uiResources['rxPdpFormTotalPillsInputLabel'].value}</legend>
                            {daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.DIRECT && (
                                <button
                                    className="text-xsm underline"
                                    onClick={() =>
                                        setDaySupplyInputMethod(PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE)
                                    }
                                    type="button"
                                >
                                    {uiResources['rxPdpFormRangePillInputButtonLabel'].value}
                                </button>
                            )}
                            <input
                                value={totalPillCountInput}
                                name={totalPillCountInputName}
                                onChange={(e) => {
                                    setTotalPillCountInput(parseInt(e.target.value) + '')
                                    setTouchedFormInputs((tInputs) => {
                                        if (!tInputs.includes(totalPillCountInputName)) {
                                            return [...tInputs, totalPillCountInputName]
                                        }

                                        return tInputs
                                    })
                                }}
                                className={textInputClassName}
                                placeholder={uiResources['rxPdpFormTotalPillInputPlaceholder'].value}
                                type="number"
                                min={minimumTotalPills}
                                step={1}
                                disabled={daySupplyInputMethod === PRESCRIPTION_QUANTITY_INPUT_METHOD.COMPOSITE}
                                data-cy="total-pills-input"
                            />
                            {/* error message */}
                            {formErrors[totalPillCountInputName] &&
                                touchedFormInputs.includes(totalPillCountInputName) && (
                                    <ErrorMessage className={errorMessageClassName}>
                                        {formErrors[totalPillCountInputName]}
                                    </ErrorMessage>
                                )}
                        </fieldset>

                        <div className="py-1 text-sm mt-4">{uiResources['rxLandingPageAddMedicationsLabel'].value}</div>
                    </div>
                </div>

                {priceOfSelectedDrug && (
                    <div className="mt-1 mb-2.5">
                        <PriceSavingsCallout
                            price={Number(priceOfSelectedDrug?.membership_total_price)}
                            comparePrice={Number(priceOfSelectedDrug?.standard_total_price)}
                            loading={loadingDrugPrices}
                        />
                    </div>
                )}

                <div className="flex justify-between items-end mt-0 pb-4">
                    <button
                        className="underline underline-offset-4"
                        onClick={() => {
                            setSubstance(null)
                        }}
                    >
                        {uiResources['rxLandingPageBackToSearchLabel'].value}
                    </button>
                    <div>
                        <Button
                            type="submit"
                            form={formId}
                            className={`min-w-[150px] ml-auto ${loadingSubstances ? 'pb-3' : ''} ${
                                formSubmissionState === 'submitting' ? '' : 'flex items-center justify-center gap-x-2'
                            }`}
                            data-cy="drawer-form-submit-button"
                            loading={formSubmissionState === 'submitting' || loadingDrugPrices}
                            disabled={formSubmissionState === 'submitting' || loadingDrugPrices}
                        >
                            {uiResources['rxLandingPageCtaLabel'].value}{' '}
                            <ArrowRightIcon strokeWidth={2} pathClassName="stroke-white" height="12" width="12" />
                        </Button>
                    </div>
                </div>
            </form>
        )
    }

    const drugPricesErrorMessageRenderNode: RenderNode = React.useMemo(
        () => ({
            [BLOCKS.PARAGRAPH]: (_node, children): React.ReactNode => {
                // renders word/s inside {curly brace}
                // eg: contact our {customer care} => contact our <button>customer care</button>
                return React.Children.map(children, (child) => {
                    if (typeof child !== 'string') {
                        return child
                    }

                    const matchedWord = child.match(wordInsideCurlyBracketsRegexp)
                    if (!matchedWord) {
                        return child
                    }

                    const templateSplit: React.ReactNode[] = child.split(wordInsideCurlyBracketsRegexp)

                    const matchedWordExcludingBraces = matchedWord?.[0]?.replace('{', '').replace('}', '')

                    // insert the button at index 1 which is the position of the
                    // word/s inside curly braces
                    templateSplit.splice(
                        1,
                        0,
                        <button type="button" className="underline" onClick={() => refetchDrugPrices()}>
                            {matchedWordExcludingBraces}
                        </button>,
                    )

                    return templateSplit
                })
            },
        }),
        [refetchDrugPrices],
    )

    const renderSubstances = () => {
        if (loadingSubstances) {
            return new Array(7).fill('').map((_, i) => (
                <div key={i} className="flex justify-between space-x-4 items-center animate-pulse">
                    <div className="flex flex-col space-y-3 border-t border-gray-medium py-2.5">
                        <div className="h-8 w-36 bg-gray-medium rounded-5" />
                        <div className="h-6 w-80 bg-gray-medium rounded-5" />
                    </div>

                    <div className="h-10 w-10 rounded-full bg-gray-medium" />
                </div>
            ))
        }

        if (substancesLoadingError) {
            return (
                <ContentfulRichText
                    content={commonCopy['rxOnboardingMedicineSearchError'].value.json}
                    className="text-gray-darker"
                    ignoreMarkdownStyles
                    renderNode={substanceErrorMessageRenderNode}
                />
            )
        }

        return substances.map((substance) => {
            const filteredTradeNames = substance.trade_names.filter(
                (tradeName) => tradeName.toLowerCase() !== substance.name.toLowerCase(),
            )

            return (
                <React.Fragment key={substance.id}>
                    <button
                        key={substance.id}
                        type="button"
                        className="w-full flex justify-between space-x-2 items-center border-t border-gray-medium py-2.5 group text-left"
                        onClick={() => {
                            setSubstance(substance)
                            setDrugFormName(null)
                            setDrug(null)
                            setDaySupply(daySupplyOptions[daySupplyOptions.length - 1].value)
                            setPillsPerDayInput(1)
                            setTotalPillCountInput('1')

                            setTouchedFormInputs([])
                        }}
                        data-cy="substance-button"
                    >
                        <div>
                            <div className="font-bold capitalize">{substance.name}</div>
                            {filteredTradeNames.length > 0 && (
                                <div className="text-gray-darker">
                                    {uiResources['rxMedicineSearchFormGenericForLabel'].value}{' '}
                                    <span className="capitalize">
                                        {filteredTradeNames.map((tradeName, index, self) => {
                                            if (index === self.length - 1) {
                                                return tradeName
                                            }

                                            return `${tradeName}, `
                                        })}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className="bg-transparent border rounded-full py-2.5 px-3 cursor-pointer"
                            onClick={() => setSubstance(substance)}
                        >
                            <ArrowRightIcon strokeWidth={2} pathClassName="stroke-black" />
                        </div>
                    </button>
                </React.Fragment>
            )
        })
    }

    const textInputClassName =
        'font-bold mt-3 w-full rounded-full px-5 py-2.5 bg-white shadow-[0px_0px_10px_3px_rgba(0,0,0,0.15)]'

    return (
        <div className="grid grid-cols-1">
            <div className="flex space-x-2">
                <div
                    className={`flex flex-grow items-center bg-white rounded-4xl border border-black px-8 py-8 focus-within:ring focus-within:ring-gray ${
                        showResults ? 'border-b-0 rounded-b-none focus-within:ring-0' : ''
                    }`}
                >
                    <MagnifyingGlassIcon />
                    <input
                        type={searchInput.name}
                        placeholder={searchInput.placeholder}
                        className={`font-bold placeholder:text-gray w-full focus-visible:!ring-0 ml-4 ${styles['search-input']}`}
                        onChange={handleSearchTermOnChange}
                        value={searchTerm}
                        data-cy="search-input"
                    />

                    {/* Hide icon during active search */}
                    {searchTerm === '' && (
                        <div className="bg-black rounded-full py-2.5 px-3 cursor-pointer">
                            <ArrowRightIcon strokeWidth={2} pathClassName="stroke-white" />
                        </div>
                    )}
                </div>
            </div>

            {/* Search results dropdown */}
            {showResults && (
                <div className="relative">
                    <div className="absolute left-0 right-0 z-10">
                        <div className="space-x-2 bg-white rounded-4xl rounded-t-none border-t-0 border border-black text-xbase px-4 py-4 text-gray-darker min-h-[200px] sm:min-h-[0px] overflow-hidden shadow-lg">
                            {substance ? renderForm() : <ul className="h-full">{renderSubstances()}</ul>}
                        </div>
                    </div>
                </div>
            )}

            {/* Icons + Copy */}
            {showIconsAndCopy && (
                <>
                    <hr className="my-9 xl:-mr-40 h-[0.25px] border-t-0 bg-gray-medium" />
                    <div className="grid grid-cols-2 gap-6">
                        {iconResourceKeys.map(
                            (key) =>
                                uiResources[key] &&
                                uiResources[key].media && (
                                    <div key={key} className="flex items-center space-x-2">
                                        <img
                                            src={uiResources[key].media.url}
                                            alt={uiResources[key].media.title}
                                            loading="lazy"
                                            className="h-[22px] w-[22px]"
                                        />
                                        <p className="text-sm">{uiResources[key].value}</p>
                                    </div>
                                ),
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default RxSearchForm
