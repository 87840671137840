/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import Carousel from 'components/carousel'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import styles from './style.module.css'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import Link from 'next/link'

interface IProps {
    section: IPageSectionVisualizer
}

const _2xlBreakpoint = screens['2xl'].replace('px', '')

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="section--header lg:text-3.25xl leading-120% tracking-tight font-normal">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xbase lg:text-base leading-140%">{children}</p>,
}

const intersectionObserverOptions: IntersectionObserverInit = {
    // only intersecting if 70% of the element is visible
    threshold: 0.7,
}

export const VisualizerCollectionSection = ({ section }: IProps): React.ReactElement => {
    const [activeVisualIndex, setActiveVisualIndex] = React.useState(0)
    const [isAnimating, setIsAnimating] = React.useState(false)

    const carouselRef = React.useRef(null)

    const { richTitle, subHeader, visualsCollection } = section

    const visuals = visualsCollection?.items ?? []

    const sectionBackgroundColor = visuals?.[activeVisualIndex]?.associatedColors

    const [sectionElement, setSectionElement] = React.useState<HTMLElement>()

    // slightly different than entry.isIntersecting
    // this state is true and remains true as the
    // section intersects for the first time
    const [sectionHasBeenScrolledTo, setSectionHasBeenScrolledTo] = React.useState(false)

    const timerRef = React.useRef<NodeJS.Timeout>()

    const sectionRef = React.useCallback((node) => {
        if (node !== null) {
            setSectionElement(node)
        }
    }, [])

    // wrap inside useCallback to prevent adding/removing
    // event listener in every render
    const onSlideVisible = React.useCallback((event: CustomEvent) => {
        const {
            detail: { slide },
        } = event
        setActiveVisualIndex(slide)
    }, [])

    // wrap inside useCallback to prevent adding/removing
    // event listener in every render
    const onAnimationComplete = React.useCallback(() => {
        setIsAnimating(false)
    }, [])

    const slideTo = React.useCallback((slideIndex: number) => {
        setIsAnimating(true)
        carouselRef.current?.scrollItem(slideIndex)
    }, [])

    const { entry, observer } = useIntersectionObserver({
        element: sectionElement,
        options: intersectionObserverOptions,
    })

    const isMobile = useMobileScreenDetection()

    React.useEffect(() => {
        if (entry?.isIntersecting && !sectionHasBeenScrolledTo) {
            setSectionHasBeenScrolledTo(true)
        }
    }, [entry?.isIntersecting, sectionHasBeenScrolledTo])

    // autoplay slides
    React.useEffect(() => {
        // only run when the visualizer section is visible
        // and on desktop screens
        if (!entry?.isIntersecting || isMobile) {
            return
        }

        timerRef.current = setInterval(() => {
            setActiveVisualIndex((activeVisualIndex) => {
                let nextIndex

                if (activeVisualIndex >= visuals.length - 1) {
                    nextIndex = 0
                } else {
                    nextIndex = activeVisualIndex + 1
                }

                slideTo(nextIndex)

                return nextIndex
            })
        }, 2000)

        return () => {
            clearTimeout(timerRef.current)
        }
    }, [visuals.length, slideTo, entry?.isIntersecting, isMobile])

    if (!section) {
        return null
    }

    return (
        <section
            ref={sectionRef}
            className="section p-0 grid grid-cols-1 lg:grid-cols-2"
            style={{ backgroundColor: sectionBackgroundColor }}
        >
            <div className="px-9 py-15 lg:p-16 lg:pr-24">
                {richTitle?.json && (
                    <h2 className="text-center lg:text-left">
                        <ContentfulRichText content={richTitle.json} renderNode={headerRenderNode} />
                    </h2>
                )}

                {subHeader?.json && (
                    <div className="mt-6 lg:mt-4 text-center lg:text-left">
                        <ContentfulRichText content={subHeader.json} renderNode={subHeaderRenderNode} />
                    </div>
                )}

                <hr className="hidden lg:block  border-black mt-7.5" />

                {/* visual images - mobile  */}
                {visuals.length > 0 && (
                    <div className="lg:hidden -mx-9 mt-11 relative">
                        <Carousel
                            ref={carouselRef}
                            slidesToShow={1.25}
                            className={styles['carousel-snap']}
                            snap={true}
                            animating={isAnimating}
                            onSlideVisible={onSlideVisible}
                            onAnimationComplete={onAnimationComplete}
                        >
                            {visuals.map((visual, index) => {
                                const { mobileImagesCollection, sys, cta } = visual ?? {}
                                const mobileImage = mobileImagesCollection?.items?.[0]

                                if (!mobileImage?.url) {
                                    return null
                                }

                                const imageElement = (
                                    <>
                                        <picture className="h-full w-full rounded-2lg overflow-hidden">
                                            {/* webp */}
                                            <source
                                                type="image/webp"
                                                srcSet={`
                                        ${mobileImage.url}?fm=webp&w=320 320w,
                                        ${mobileImage.url}?fm=webp&w=480 480w,
                                        ${mobileImage.url}?fm=webp&w=640 640w,
                                        ${mobileImage.url}?fm=webp&w=1024 1024w
                                    `}
                                                sizes="80vw"
                                            />

                                            {/* jepg */}
                                            <source
                                                type="image/jpeg"
                                                srcSet={`
                                        ${mobileImage.url}?fm=jpg&w=320 320w,
                                        ${mobileImage.url}?fm=jpg&w=480 480w,
                                        ${mobileImage.url}?fm=jpg&w=640 640w,
                                        ${mobileImage.url}?fm=jpg&w=1024 1024w
                                    `}
                                                sizes="80vw"
                                            />

                                            <img
                                                src={mobileImage.url + 'fm=jpg&w=480'}
                                                alt={mobileImage.description || mobileImage.title}
                                                height={mobileImage.height}
                                                width={mobileImage.width}
                                                className="h-full w-full object-cover object-bottom"
                                                loading="lazy"
                                            />
                                        </picture>

                                        <div
                                            className={`text-3xl text-center leading-110% tracking-tight w-full mt-9 whitespace-nowrap`}
                                        >
                                            {visual.title}
                                        </div>
                                    </>
                                )

                                if (cta?.href) {
                                    return (
                                        <Link
                                            href={cta.href}
                                            key={sys?.id || index}
                                            className="flex flex-col pl-4 first:pl-0"
                                        >
                                            {imageElement}
                                        </Link>
                                    )
                                }

                                return (
                                    <div key={sys?.id || index} className="flex flex-col pl-4 first:pl-0">
                                        {imageElement}
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                )}

                {/* visuals button list desktop */}
                <ul className="mt-7.5 hidden lg:flex lg:flex-col items-start space-y-2">
                    {visuals.map(
                        (visual, index) =>
                            visual && (
                                <button
                                    key={visual.sys?.id || index}
                                    onClick={() => {
                                        observer?.disconnect()
                                        clearTimeout(timerRef.current)
                                        setActiveVisualIndex(index)
                                    }}
                                    className={`text-6.25xl leading-110% tracking-tight md:text-left ${
                                        activeVisualIndex === index ? 'opacity-100' : 'opacity-50'
                                    }`}
                                >
                                    {visual.title}
                                </button>
                            ),
                    )}
                </ul>
            </div>

            {/* visuals - desktop */}
            {visuals.length > 0 && !isMobile && (
                <div className="hidden lg:grid grid-cols-1 grid-rows-1">
                    {visuals.map((visual, index) => {
                        const { desktopImagesCollection, sys } = visual ?? {}
                        const desktopImage = desktopImagesCollection?.items?.[0]

                        if (!desktopImage?.url) {
                            return null
                        }

                        return (
                            <picture
                                key={sys?.id || index}
                                className={`col-start-1 col-end-2 row-start-1 row-end-1 transition-opacity duration-500 ${
                                    activeVisualIndex === index ? 'opacity-100' : 'opacity-0'
                                }`}
                            >
                                {/* webp */}
                                <source
                                    type="image/webp"
                                    srcSet={`
                                    ${desktopImage.url}?fm=webp&w=1024 1024w,
                                    ${desktopImage.url}?fm=webp&w=1280 1280w,
                                    ${desktopImage.url}?fm=webp&w=1600 1600w
                                `}
                                    // max width of page is _2xlBreakpoint, so starting
                                    // from _2xl breakpoint the width of image will be
                                    // half of _2xl breakpoint
                                    sizes={`(min-width: ${_2xlBreakpoint}px) ${Number(_2xlBreakpoint) / 2}px, 50vw`}
                                />

                                {/* jpeg */}
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                    ${desktopImage.url}?fm=jpg&w=1024 1024w,
                                    ${desktopImage.url}?fm=jpg&w=1280 1280w,
                                    ${desktopImage.url}?fm=jpg&w=1600 1600w
                                `}
                                    sizes={`(min-width: ${_2xlBreakpoint}px) ${Number(_2xlBreakpoint) / 2}px, 50vw`}
                                />

                                <img
                                    src={desktopImage.url + 'fm=jpg&w=1280'}
                                    alt={desktopImage.description || desktopImage.title}
                                    height={desktopImage.height}
                                    width={desktopImage.height}
                                    className="h-full w-full object-cover object-bottom"
                                    loading="lazy"
                                />
                            </picture>
                        )
                    })}
                </div>
            )}
        </section>
    )
}
