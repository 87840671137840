import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import PlusIcon from 'icons/plus'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { colors } from 'theme.cjs'
import styles from './style.module.css'
import MinusIcon from 'icons/minus'
import { getContentfulImageBackgroundQueryFromTailwindClass } from 'lib/util/image'
import { IconButton } from 'components/buttons/button'
import ArrowRightIcon from 'icons/arrow-right'
import Carousel from 'components/carousel'
import globalManifest from 'data/global-manifest.json'
import { IUiResources } from 'interfaces/ui-resource'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import Link from 'next/link'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
    uiResources: IUiResources
}

const { resources: globalResources } = globalManifest ?? {}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className={`section--header hero--header font-normal ${styles['section-title']}`}>{children}</p>
    ),
}

const sectionBackgroundClassname = 'bg-white'

const contentfulBGQuery = getContentfulImageBackgroundQueryFromTailwindClass(sectionBackgroundClassname)

const intersectionObserverOptions: IntersectionObserverInit = {
    // only intersecting if 70% of the element is visible
    threshold: 0.7,
}

export const VisualizerSlidesSection = ({ section, uiResources }: IProps): React.ReactElement => {
    const { richTitle, visualsCollection, ctaLink, backgroundColor } = section ?? {}

    const visuals = visualsCollection?.items || []

    const [activeVisualIndex, setActiveVisualIndex] = React.useState(0)
    const [isAnimating, setIsAnimating] = React.useState(false)

    const [sectionElement, setSectionElement] = React.useState<HTMLElement>()

    // slightly different than entry.isIntersecting
    // this state is true and remains true as the
    // section intersects for the first time
    const [sectionHasBeenScrolledTo, setSectionHasBeenScrolledTo] = React.useState(false)

    const timerRef = React.useRef<NodeJS.Timeout>()

    const sectionRef = React.useCallback((node) => {
        if (node !== null) {
            setSectionElement(node)
        }
    }, [])

    const { entry, observer } = useIntersectionObserver({
        element: sectionElement,
        options: intersectionObserverOptions,
    })

    const carouselRef = React.useRef(null)

    const slideTo = React.useCallback((slideIndex: number) => {
        setIsAnimating(true)
        carouselRef.current?.scrollItem(slideIndex)
    }, [])

    React.useEffect(() => {
        if (entry?.isIntersecting && !sectionHasBeenScrolledTo) {
            setSectionHasBeenScrolledTo(true)
        }
    }, [entry?.isIntersecting, sectionHasBeenScrolledTo])

    // autoplay slides
    React.useEffect(() => {
        // only run when the visualizer section is visible
        if (!entry?.isIntersecting) {
            return
        }

        timerRef.current = setInterval(() => {
            setActiveVisualIndex((activeVisualIndex) => {
                let nextIndex

                if (activeVisualIndex >= visuals.length - 1) {
                    nextIndex = 0
                } else {
                    nextIndex = activeVisualIndex + 1
                }

                slideTo(nextIndex)

                return nextIndex
            })
        }, 2000)

        return () => {
            clearTimeout(timerRef.current)
        }
    }, [visuals.length, slideTo, entry?.isIntersecting])

    // wrap inside useCallback to prevent adding/removing
    // event listener in every render
    const onSlideVisible = React.useCallback((event: CustomEvent) => {
        const {
            detail: { slide },
        } = event
        setActiveVisualIndex(slide)
    }, [])

    // wrap inside useCallback to prevent adding/removing
    // event listener in every render
    const onAnimationComplete = React.useCallback(() => {
        setIsAnimating(false)
    }, [])

    const goToPrevSlide = () => {
        slideTo(activeVisualIndex - 1)
        observer?.disconnect()
        clearTimeout(timerRef.current)
    }

    const goToNextSlide = () => {
        slideTo(activeVisualIndex + 1)
        observer?.disconnect()
        clearTimeout(timerRef.current)
    }

    if (!visuals.length) {
        return null
    }

    const isCTALinkDefined = ctaLink?.href && ctaLink?.name

    return (
        <section
            ref={sectionRef}
            className={`p-0 pb-10 lg:pb-0 grid ${sectionBackgroundClassname}`}
            style={{ backgroundColor }}
        >
            <div
                className={`section pt-5 pb-10 lg:py-15 col-start-1 col-end-2 row-start-1 row-end-2 relative z-10 lg:w-max ${styles['title-container']}`}
            >
                {richTitle && (
                    <div>
                        <ContentfulRichText content={richTitle.json} renderNode={headerRenderNode} />
                    </div>
                )}
                {/*visual titles - desktop */}
                <div className="hidden lg:grid mt-15 gap-y-6">
                    {visuals.map(
                        (visual, index) =>
                            visual && (
                                <button
                                    onClick={() => {
                                        setActiveVisualIndex(index)
                                        observer?.disconnect()
                                        clearTimeout(timerRef.current)
                                    }}
                                    className="w-max flex items-center"
                                    key={visual.sys?.id}
                                >
                                    <span
                                        className={`text-xl leading-120% tracking-tight ${
                                            activeVisualIndex === index ? 'font-bold' : ''
                                        }`}
                                    >
                                        {visual.title}
                                    </span>
                                    <div className="ml-4 rounded-full border border-gray h-6 w-6 flex justify-center items-center">
                                        {activeVisualIndex === index ? (
                                            <MinusIcon fill={colors.mineshaft} />
                                        ) : (
                                            <PlusIcon fill={colors.mineshaft} />
                                        )}
                                    </div>
                                </button>
                            ),
                    )}
                </div>

                {/* cta link */}
                {isCTALinkDefined && (
                    <Link
                        href={ctaLink.href}
                        className="button button-primary section--primary-cta hidden lg:inline-block mt-14 lg:px-12 lg:py-2.5 text-xbase"
                    >
                        {ctaLink.name}
                    </Link>
                )}
            </div>

            {/* image */}
            <div className="grid relative w-full h-full col-start-1 col-end-2 row-start-1 row-end-2">
                {visuals.map((visual, index) => {
                    const mobileImage = visual.mobileImagesCollection?.items?.[0]
                    const desktopImage = visual.desktopImagesCollection?.items?.[0]

                    if (!mobileImage && !desktopImage) {
                        return null
                    }

                    // we're placing images on top of each other
                    // and changing opacity to transition between them
                    // which means although lazily loaded all images
                    // will be loaded at once, and in pages where this section
                    // appears right below the fold means that we're requesting
                    // all this images right away, to prevent this only render the
                    // first image and wait for intersection to render remaining images
                    if (index !== 0 && !sectionHasBeenScrolledTo) {
                        return null
                    }

                    return (
                        <picture key={visual.sys?.id} className="col-start-1 col-end-2 row-start-1 row-end-2">
                            {desktopImage && (
                                <>
                                    {/* desktop image - webp */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint})`}
                                        type="image/webp"
                                        srcSet={`
                                            ${desktopImage.url}?fm=webp&w=1024 1024w,
                                            ${desktopImage.url}?fm=webp&w=1280 1280w,
                                            ${desktopImage.url}?fm=webp&w=1440 1440w,
                                            ${desktopImage.url}?fm=webp&w=1600 1600w,
                                            ${desktopImage.url}?fm=webp&w=2400 2400w,
                                            ${desktopImage.url}?fm=webp&w=3200 3200w
                                        `}
                                    />
                                    {/* desktop image - jpeg */}
                                    <source
                                        media={`(min-width: ${lgBreakPoint})`}
                                        type="image/jpeg"
                                        srcSet={`
                                            ${desktopImage.url}?fm=jpg&w=1024&${contentfulBGQuery} 1024w,
                                            ${desktopImage.url}?fm=jpg&w=1280&${contentfulBGQuery} 1280w,
                                            ${desktopImage.url}?fm=jpg&w=1440&${contentfulBGQuery} 1440w,
                                            ${desktopImage.url}?fm=jpg&w=1600&${contentfulBGQuery} 1600w,
                                            ${desktopImage.url}?fm=jpg&w=2400&${contentfulBGQuery} 2400w,
                                            ${desktopImage.url}?fm=jpg&w=3200&${contentfulBGQuery} 3200w
                                        `}
                                    />
                                </>
                            )}

                            {mobileImage && (
                                <>
                                    {/* mobile image - webp */}
                                    <source
                                        type="image/webp"
                                        srcSet={`
                                            ${mobileImage.url}?fm=webp&w=320 320w,
                                            ${mobileImage.url}?fm=webp&w=420 420w,
                                            ${mobileImage.url}?fm=webp&w=640 640w,
                                            ${mobileImage.url}?fm=webp&w=840 840w,
                                            ${mobileImage.url}?fm=webp&w=1024 1024w
                                        `}
                                    />

                                    {/* mobile image - jpeg */}
                                    <source
                                        type="image/jpeg"
                                        srcSet={`
                                            ${mobileImage.url}?fm=jpg&w=320&${contentfulBGQuery} 320w,
                                            ${mobileImage.url}?fm=jpg&w=420&${contentfulBGQuery} 420w,
                                            ${mobileImage.url}?fm=jpg&w=640&${contentfulBGQuery} 640w,
                                            ${mobileImage.url}?fm=jpg&w=840&${contentfulBGQuery} 840w,
                                            ${mobileImage.url}?fm=jpg&w=1024&${contentfulBGQuery} 1024w
                                        `}
                                    />
                                </>
                            )}

                            <img
                                src={(mobileImage.url || desktopImage.url) + '?fm=jpg&w=1024&' + contentfulBGQuery}
                                alt={
                                    mobileImage.title ||
                                    desktopImage.title ||
                                    mobileImage.description ||
                                    desktopImage.description
                                }
                                height={mobileImage.height || desktopImage.height}
                                width={mobileImage.width || desktopImage.width}
                                className={`
                                    lg:max-h-[640px] lg:w-auto lg:mx-auto
                                    transition-opacity duration-500
                                    ${activeVisualIndex === index ? 'opacity-100' : 'opacity-0'}
                                `}
                                loading="lazy"
                            />
                        </picture>
                    )
                })}

                {/* dots */}
                <div role="tablist" className="flex lg:hidden justify-center items-center mt-2.5">
                    {visuals.map((visual, index) => (
                        <button
                            key={visual.sys?.id}
                            onClick={() => {
                                slideTo(index)
                                observer?.disconnect()
                                clearTimeout(timerRef.current)
                            }}
                            className="p-2 bg-transparent rounded-full"
                            role="tab"
                            aria-label={`${uiResources?.['gotoSlideLabel']?.value} ${index + 1}`}
                        >
                            <div
                                className={`w-2 h-2 rounded-full ${
                                    activeVisualIndex === index ? 'bg-black' : 'bg-gray'
                                }`}
                            />
                        </button>
                    ))}
                </div>
            </div>

            {/* visual titles and descriptions - mobile */}
            <div className={`lg:hidden mt-5 overflow-hidden relative ${styles['description-carousel-container']}`}>
                <Carousel
                    animating={isAnimating}
                    ref={carouselRef}
                    onAnimationComplete={onAnimationComplete}
                    onSlideVisible={onSlideVisible}
                >
                    {visuals.map((visual) => {
                        return (
                            <div key={visual.sys?.id} className="text-center leading-140% flex flex-col !justify-start">
                                <div className="text-1xl">{visual.title}</div>
                                <p className="mt-6 text-center px-16">{visual.description}</p>
                            </div>
                        )
                    })}
                </Carousel>

                {/* navigation buttons */}
                <IconButton
                    onClick={goToPrevSlide}
                    className={`${
                        isCTALinkDefined ? `${sectionBackgroundClassname} border` : 'button-primary'
                    } lg:hidden h-9 w-9 flex justify-center items-center absolute top-0 left-2.5 mt-5`}
                    disabled={activeVisualIndex === 0}
                    label={globalResources?.['nextLabel']?.value as string}
                >
                    <ArrowRightIcon
                        strokeWidth={2}
                        stroke={isCTALinkDefined ? colors.mineshaft : colors.white}
                        className="rotate-180"
                    />
                </IconButton>
                <IconButton
                    onClick={goToNextSlide}
                    className={`${
                        isCTALinkDefined ? `${sectionBackgroundClassname} border` : 'button-primary'
                    } ml-4 button-primary lg:hidden h-9 w-9 flex justify-center items-center absolute top-0 right-2.5 mt-5`}
                    disabled={activeVisualIndex >= visuals.length - 1}
                    label={globalResources?.['nextLabel']?.value as string}
                >
                    <ArrowRightIcon strokeWidth={2} stroke={isCTALinkDefined ? colors.mineshaft : colors.white} />
                </IconButton>
            </div>

            {/* cta link */}
            {isCTALinkDefined && (
                <Link href={ctaLink.href} className="button button-primary block lg:hidden mt-6 mx-5 text-xbase">
                    {ctaLink.name}
                </Link>
            )}
        </section>
    )
}
