/* eslint-disable react/display-name */
import * as React from 'react'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionForm } from 'interfaces/page-section-form'
import RxSearchForm from 'components/rx-search-form'
import { IUiResources } from 'interfaces/ui-resource'
import { ICommonCopyDict } from 'interfaces/common-copy'
import Picture from 'components/picture'

interface IProps {
    section: IPageSectionForm
    uiResources: IUiResources
    commonCopy: ICommonCopyDict
    className?: string
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="section--header-small leading-110% tracking-tighter">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="section--subheader lg:text-lg font-medium leading-140% tracking-tight">{children}</p>
    ),
}

export const SearchFormSection = ({ section, uiResources, className = '', commonCopy }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { header, subHeader, inputsCollection, alignImage: alignment, backgroundColor, media } = section

    const searchInput = inputsCollection.items[0]

    // we have different spacing for sections with background
    const useSmallMarginSpacing = backgroundColor && backgroundColor !== 'transparent'

    return (
        <section
            className={`section--v2 ${media ? 'flex flex-col md:flex-row' : ''} ${
                useSmallMarginSpacing ? 'section--small-margin-block' : ''
            }  ${className}`}
            style={{ backgroundColor }}
        >
            <div className={`flex-1 ${media ? 'justify-end flex' : ''}`}>
                <div
                    className={`max-w-xl ${
                        media
                            ? 'mx-auto w-full'
                            : alignment === 'center'
                            ? 'mx-auto'
                            : alignment === 'right'
                            ? 'ml-auto'
                            : ''
                    }`}
                >
                    {header?.json && (
                        <h2>
                            <ContentfulRichText
                                className={`${
                                    media
                                        ? 'text-center md:text-auto'
                                        : alignment === 'center'
                                        ? 'text-center'
                                        : alignment === 'right'
                                        ? 'text-right'
                                        : ''
                                }`}
                                content={header.json}
                                renderNode={headerRenderNode}
                            />
                        </h2>
                    )}

                    <div
                        className={`mt-8 relative min-h-[50px] w-full md:max-w-lg ${
                            alignment === 'center' ? 'mx-auto' : alignment === 'right' ? 'ml-auto' : ''
                        }`}
                    >
                        <div className="absolute w-full z-20">
                            <RxSearchForm uiResources={uiResources} commonCopy={commonCopy} searchInput={searchInput} />
                        </div>
                    </div>

                    {subHeader?.json && (
                        <div className="mt-16">
                            <ContentfulRichText
                                className={
                                    alignment === 'center' ? 'text-center' : alignment === 'right' ? 'text-right' : ''
                                }
                                content={subHeader.json}
                                renderNode={subHeaderRenderNode}
                            />
                        </div>
                    )}
                </div>
            </div>
            {media && (
                <div className="flex-1 flex justify-center mt-8 md:mt-0">
                    <Picture asset={media} className="max-w-100 md:max-w-sm" />
                </div>
            )}
        </section>
    )
}
