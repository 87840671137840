import * as React from 'react'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import { easeInOutQuad } from 'lib/util/misc'

interface IProps {
    scrollableContainerEl: HTMLElement
    cardWidth: number
    hideIfNoScroll?: boolean
}

// arrow buttons to navigate horizontally scrolling section
export const ArrowNavigation = ({ scrollableContainerEl, cardWidth, hideIfNoScroll }: IProps): React.ReactElement => {
    const [doesSectionHaveScrollbar, setDoesSectionHaveScrollbar] = React.useState(false)
    const [isLeftArrowDisabled, setIsLeftArrowDisabled] = React.useState(true)
    const [isRightArrowDisabled, setIsRightArrowDisabled] = React.useState(false)

    // initial setup
    React.useEffect(() => {
        if (!scrollableContainerEl) {
            return
        }

        const scrollWidth = scrollableContainerEl.scrollWidth
        const clientWidth = scrollableContainerEl.clientWidth
        const scrollLeft = scrollableContainerEl.scrollLeft

        setDoesSectionHaveScrollbar(scrollWidth > clientWidth)

        if (scrollLeft === 0) {
            setIsLeftArrowDisabled(true)
        } else {
            setIsLeftArrowDisabled(false)
        }

        if (clientWidth + scrollLeft >= scrollWidth) {
            setIsRightArrowDisabled(true)
        } else {
            setIsRightArrowDisabled(false)
        }
    }, [scrollableContainerEl])

    // screen resize handler
    React.useEffect(() => {
        if (!scrollableContainerEl) {
            return
        }

        let timer: NodeJS.Timeout

        const windowResizeHandler = () => {
            clearTimeout(timer)

            const scrollWidth = scrollableContainerEl.scrollWidth
            const clientWidth = scrollableContainerEl.clientWidth

            timer = setTimeout(() => {
                setDoesSectionHaveScrollbar(scrollWidth > clientWidth)
            }, 1000)
        }

        window.addEventListener('resize', windowResizeHandler)

        return () => {
            window.removeEventListener('resize', windowResizeHandler)
        }
    }, [scrollableContainerEl])

    // handle scroll
    React.useEffect(() => {
        if (!scrollableContainerEl) {
            return
        }

        const handleScroll = (e: Event) => {
            const currentTarget = e.currentTarget as HTMLElement

            const scrollLeft = currentTarget.scrollLeft
            const scrollWidth = currentTarget.scrollWidth
            const clientWidth = currentTarget.clientWidth

            if (scrollLeft === 0) {
                setIsLeftArrowDisabled(true)
            } else {
                setIsLeftArrowDisabled(false)
            }

            if (clientWidth + scrollLeft >= scrollWidth) {
                setIsRightArrowDisabled(true)
            } else {
                setIsRightArrowDisabled(false)
            }
        }

        scrollableContainerEl.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            scrollableContainerEl.removeEventListener('scroll', handleScroll)
        }
    }, [scrollableContainerEl])

    // handle dom mutation
    // update arrow states if dom changes
    React.useEffect(() => {
        if (!scrollableContainerEl) {
            return
        }

        const handleDOMMutation = () => {
            const scrollLeft = scrollableContainerEl.scrollLeft
            const scrollWidth = scrollableContainerEl.scrollWidth
            const clientWidth = scrollableContainerEl.clientWidth

            setDoesSectionHaveScrollbar(scrollWidth > clientWidth)

            if (scrollLeft === 0) {
                setIsLeftArrowDisabled(true)
            } else {
                setIsLeftArrowDisabled(false)
            }

            if (clientWidth + scrollLeft >= scrollWidth) {
                setIsRightArrowDisabled(true)
            } else {
                setIsRightArrowDisabled(false)
            }
        }

        // Options for the observer (which mutations to observe)
        const config = { attributes: false, childList: true }

        // Callback function to execute when mutations are observed
        const callback = () => {
            handleDOMMutation()
        }

        const mutationObserver = new MutationObserver(callback)
        mutationObserver.observe(scrollableContainerEl, config)

        return () => {
            mutationObserver.disconnect()
        }
    }, [scrollableContainerEl])

    const handleLeftButtonClick = () => {
        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContainerEl.scrollBy({ left: cardWidth * -1, behavior: 'smooth' })
            return
        }

        const startTime = performance.now()
        const duration = 0.5
        const startPosition = scrollableContainerEl.scrollLeft
        const endPosition = startPosition - cardWidth < 0 ? 0 : startPosition - cardWidth
        const scrollBy = endPosition - startPosition

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.floor(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))
            scrollableContainerEl.scrollLeft = newPosition

            if (scrollableContainerEl.scrollLeft > endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }

    const handleRightButtonClick = () => {
        if (window.CSS.supports('scroll-behavior', 'smooth')) {
            scrollableContainerEl.scrollBy({ left: cardWidth, behavior: 'smooth' })
            return
        }

        const startTime = performance.now()
        const duration = 0.5
        const scrollWidth = scrollableContainerEl.scrollWidth
        const clientWidth = scrollableContainerEl.clientWidth

        const startPosition = scrollableContainerEl.scrollLeft
        const endPosition =
            startPosition + cardWidth > scrollWidth - clientWidth
                ? scrollWidth - clientWidth
                : startPosition + cardWidth

        const scrollBy = endPosition - startPosition

        requestAnimationFrame(function scroll() {
            const timeElapsed = performance.now() - startTime
            const newPosition = Math.ceil(easeInOutQuad(timeElapsed / 1000, startPosition, scrollBy, duration))

            scrollableContainerEl.scrollLeft = newPosition

            if (scrollableContainerEl.scrollLeft < endPosition) {
                requestAnimationFrame(scroll)
            }
        })
    }

    if (!doesSectionHaveScrollbar && hideIfNoScroll) {
        return null
    }

    return (
        <div className="flex ml-10">
            {/* left */}
            <button
                className="h-9 w-9 rounded-full border flex justify-center items-center disabled:opacity-30"
                disabled={isLeftArrowDisabled}
                onClick={handleLeftButtonClick}
            >
                <ArrowRightIcon className="rotate-180" strokeWidth={2} stroke={colors.mineshaft} />
            </button>

            {/* right */}
            <button
                className="h-9 w-9 rounded-full border flex justify-center items-center ml-2 disabled:opacity-30"
                disabled={isRightArrowDisabled}
                onClick={handleRightButtonClick}
            >
                <ArrowRightIcon strokeWidth={2} stroke={colors.mineshaft} />
            </button>
        </div>
    )
}
