/* eslint-disable react/display-name */
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { wordInsideCurlyBracketsRegexp } from 'lib/util/regexps'
import Link from 'next/link'
import * as React from 'react'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    section: IPageSectionVisualizer
}

const lgBreakPoint = screens.lg

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <div className="">{children}</div>,
}

const cardDescriptionRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base leading-140% text-center">{children}</p>
    ),
}

export const VisualizerThreeInARowSection = ({ section }: IProps): React.ReactElement => {
    if (!section) {
        return null
    }

    const { richTitle, visualsCollection, backgroundColor } = section

    const visuals = visualsCollection.items ?? []

    return (
        <section className="section--v2 flex flex-col items-center" style={{ backgroundColor }}>
            {richTitle?.json && (
                <h2 className="text-center">
                    <ContentfulRichText
                        content={richTitle.json}
                        renderNode={headerRenderNode}
                        className="section--header text-3xl lg:text-3.25xl leading-110% lg:leading-120% font-normal"
                    />
                </h2>
            )}

            {visuals.length > 0 && (
                <div className="mt-15 lg:mt-20 grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-7 md:gap-y-5">
                    {visuals.map((visual, index) => {
                        const {
                            desktopImagesCollection,
                            mobileImagesCollection,
                            title,
                            richDescription,
                            description,
                            cta,
                        } = visual

                        const mobileImage = mobileImagesCollection.items[0]
                        const desktopImage = desktopImagesCollection.items[0]

                        if (!mobileImage && !desktopImage) {
                            return null
                        }

                        const mediaTitle = mobileImage?.title || desktopImage?.title
                        const mediaDescription = mobileImage?.description || desktopImage?.description

                        // break media description to extract price/discounted price
                        const priceCopy: React.ReactNode[] = mediaDescription.split(wordInsideCurlyBracketsRegexp)
                        const matchedWord = mediaDescription.match(wordInsideCurlyBracketsRegexp)

                        const matchedWordExcludingBraces = matchedWord?.[0]?.replace('{', '').replace('}', '')

                        if (matchedWord) {
                            priceCopy[1] = <s className="text-gray-medium">{matchedWordExcludingBraces}</s>
                        }

                        let ctaGridPositionClassName = 'md:row-start-2 md:row-start-3'

                        if (index === 0) {
                            ctaGridPositionClassName += ' md:col-start-1 md:col-end-2'
                        } else if (index === 1) {
                            ctaGridPositionClassName += ' md:col-start-2 md:col-end-3'
                        } else {
                            ctaGridPositionClassName += ' md:col-start-3 md:col-end-4'
                        }

                        const ctaAssociatedColors = cta?.associatedColors?.split(',')

                        return (
                            <>
                                <div
                                    key={mobileImage?.sys?.id || desktopImage?.sys?.id || index}
                                    className="first:mt-0 mt-10 md:mt-0 md:row-start-1 md:row-end-2"
                                >
                                    <div className="rounded-2lg bg-white relative p-5 h-full flex flex-col items-center justify-between">
                                        {/*
                                        Description is required but sometimes we don't want to render a tag above a column.
                                        Entering a single " " in to the field in contentful with this condition will prevent it from rendering
                                        */}
                                        {description.length > 1 && (
                                            <div
                                                className={`rounded-full px-10 py-4 text-xbase absolute left-1/2 top-0 -translate-x-1/2 -translate-y-1/2 text-center font-bold w-max ${
                                                    index === 1 ? 'bg-[#f592a4] text-white' : 'bg-gray-medium'
                                                }`}
                                            >
                                                {description}
                                            </div>
                                        )}

                                        <div>
                                            <div className="mt-6 lg:mt-11 text-1xl font-bold leading-140% text-center">
                                                {title}
                                            </div>

                                            {richDescription?.json && (
                                                <ContentfulRichText
                                                    content={richDescription.json}
                                                    renderNode={cardDescriptionRenderNode}
                                                    className="mt-1.5"
                                                />
                                            )}
                                        </div>

                                        <picture>
                                            {desktopImage?.url && (
                                                <>
                                                    {/* desktop webp */}
                                                    <source
                                                        type="image/webp"
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        srcSet={`
                                    ${desktopImage.url}?fm=webp&w=420 420w,
                                    ${desktopImage.url}?fm=webp&w=640 640w,
                                    ${desktopImage.url}?fm=webp&w=768 768w
                                `}
                                                    />

                                                    {/* desktop jpeg */}
                                                    <source
                                                        type="image/jpeg"
                                                        media={`(min-width: ${lgBreakPoint})`}
                                                        srcSet={`
                                    ${desktopImage.url}?fm=jpg&w=420 420w,
                                    ${desktopImage.url}?fm=jpg&w=640 640w,
                                    ${desktopImage.url}?fm=jpg&w=768 768w
                                `}
                                                    />
                                                </>
                                            )}

                                            {mobileImage?.url && (
                                                <>
                                                    {/* desktop webp */}
                                                    <source
                                                        type="image/webp"
                                                        srcSet={`
                                    ${mobileImage.url}?fm=webp&w=320 320w,
                                    ${mobileImage.url}?fm=webp&w=420 420w,
                                    ${mobileImage.url}?fm=webp&w=640 640w
                                `}
                                                    />

                                                    {/* desktop jpeg */}
                                                    <source
                                                        type="image/jpeg"
                                                        srcSet={`
                                    ${mobileImage.url}?fm=jpg&w=375 375w,
                                    ${mobileImage.url}?fm=jpg&w=420 420w,
                                    ${mobileImage.url}?fm=jpg&w=640 640w
                                `}
                                                    />
                                                </>
                                            )}

                                            <img
                                                src={(mobileImage?.url || desktopImage?.url) + '?fm=jpg&w=640'}
                                                alt={mediaDescription || mediaTitle}
                                                height={mobileImage?.height || desktopImage?.height}
                                                width={mobileImage?.width || desktopImage?.width}
                                                loading="lazy"
                                                // className="mx-auto"
                                            />
                                        </picture>

                                        <div className="text-center text-xl font-bold">{priceCopy}</div>
                                    </div>
                                </div>

                                {/* cta */}
                                {cta?.href && (
                                    <Link
                                        href={cta.href}
                                        className={`button button-primary w-max mx-auto ${ctaGridPositionClassName}`}
                                        style={{
                                            backgroundColor: ctaAssociatedColors?.[0],
                                            color: ctaAssociatedColors?.[1],
                                            borderColor: ctaAssociatedColors?.[2],
                                        }}
                                    >
                                        {cta.name}
                                    </Link>
                                )}
                            </>
                        )
                    })}
                </div>
            )}
        </section>
    )
}
